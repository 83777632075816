import { Observer } from 'mobx-react-lite';
import React, { MutableRefObject } from 'react';
import { Link } from 'react-router-dom';
import { AnyObject } from '../../@types/base.types';
import BaseInput from '../BaseInput/BaseInput';
import BaseSpacer from '../BaseSpacer/BaseSpacer';
import BaseToggle from '../BaseToggle/BaseToggle';
import './AccountInfoForm.scss';

type AccountInfoFormProps = {
  innerRef?: MutableRefObject<HTMLButtonElement | HTMLAnchorElement | null>;
  form : AnyObject;
}

const AccountInfoForm: React.FC<AccountInfoFormProps> = (props) => {

  return <Observer children={() => (
    <div className="AccountInfoForm">
      <p><strong>Basic Information</strong></p>
      <BaseSpacer size="md"/>
      <form className="AccountPersonalInfo">
        <BaseInput
          form={props.form}
          field="username"
          type="text"
          placeholder=""
          autoComplete="Username"
          Label="Username"
        />
        <BaseInput
          form={props.form}
          field="first_name"
          type="text"
          placeholder=""
          autoComplete="First Name"
          Label="First Name"
          required
        />
        <BaseInput
          form={props.form}
          field="last_name"
          type="text"
          placeholder=""
          autoComplete="Last Name"
          Label="Last Name"
          required
        />
        <BaseInput
          form={props.form}
          field="phone"
          type="number"
          placeholder=""
          autoComplete="Contact Number"
          Label="Contact Number"
        />
        <BaseInput
          form={props.form}
          field="email"
          type="email"
          placeholder=""
          autoComplete="Email"
          Label="Email"
          required
        />
        <BaseInput
          form={props.form}
          field="companyName"
          type="text"
          placeholder="Example Corp."
          autoComplete="company"
          Label="Company"
        />
      </form>
      <BaseSpacer size="ml"/>
      <div className="AccountInfoFormLinks">
        <Link to="/update-password">→ Update Password</Link>
        {/* <Link to="/">→ Request Assistance on Your Account</Link> */}
      </div>
      <BaseSpacer size="ml"/>
      <p><strong>Email Notifications</strong></p>
      <BaseSpacer size="ml"/>
      <div className="AccountInfoFormToggle">
        <BaseToggle field="has_disabled_email_notifications" form={props.form} label="Receive notifications for latest reports and news from Carraighill" />
      </div>
      { props.children }
    </div>
  )} />
}
export default AccountInfoForm;
