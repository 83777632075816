import { Observer } from 'mobx-react-lite';
import React from 'react';
import ClaireChistLogo from '../../assets/images/ClaireChistiLogo.png';
import DLRLogo from '../../assets/images/DlrLogo.png';
import EuropeanUnionLogo from '../../assets/images/EuropeanUnionLogo.png';
import OifigLogo from '../../assets/images/OifigLogo.png';
import './FooterLogoSet.scss';

type FooterLogoSetProps = {}


const FooterLogoSet: React.FC<FooterLogoSetProps> = props => {
  return <Observer children={() => (
   <div >
     <ul className="FooterLogoSetImageGrid">
      <li><img src={EuropeanUnionLogo} alt="EuropeanUnionLogo" /></li>
      <li><img src={DLRLogo} alt="EuropeanUnionLogo" /></li>
      <li><img src={OifigLogo} alt="EuropeanUnionLogo" /></li>
      <li><img src={ClaireChistLogo} alt="EuropeanUnionLogo" /></li>
     </ul>
     
   </div>
    
  )} />
}

export default FooterLogoSet;