import { AnyObject } from "../@types/base.types";

export function isNumber(v: any): v is number {
  return typeof v === 'number'
}
export function isNumberLike(v: any): v is number | string {
  return v !== null && (typeof v === 'number' || !isNaN(v));
}
export function isString(v: any): v is string {
  return typeof v === 'string';
}
export function isNumberOrString(v: any): v is string {
  return typeof v === 'number' || typeof v === 'string';
}
export function isBoolean(v: any): v is boolean {
  return typeof v === 'boolean';
}
export function isUndefined(v: any): v is undefined {
  return v === undefined;
}
export function isNull(v: any): v is null {
  return v === null;
}
export function isNotNull<T>(v: T): v is NonNullable<T> {
  return v !== null;
}
export function isFunction(v: any): v is (...args: any[]) => any {
  return typeof v === 'function';
}
export function isArray(v: any): v is any[] {
  return v instanceof Array
}
export function isObject<T extends AnyObject>(v: any): v is T {
  return v instanceof Object
}
export function isPromise<T = any>(v: any): v is Promise<T> {
  return v instanceof Promise
}