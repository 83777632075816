import { Observer } from 'mobx-react-lite';
import React, { MutableRefObject, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { ColorCodedState } from '../../@types/ui.types';
import { BaseButtonAppearance } from '../../controllers/ui/ui.controller.types';
import joinClassName from '../../utils/className.utils';
import { useDataAttributes } from '../../utils/dataAttributes.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import './BaseButton.scss';


export type BaseButtonEventType = React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>;

export type BaseButtonProps = React.PropsWithChildren<{
  className?: string,
  // onClick?: () => void,
  onClick?: (e?: React.MouseEvent<HTMLButtonElement>) => Promise<unknown> | unknown;
  title?: string,
  loading?: boolean,
  disabled?: boolean,
  to?: string,
  label?: string,
  Label?: ReactNode,
  appearance?: BaseButtonAppearance;
  colorCodedState?: ColorCodedState | '';
  color?: string;
  name?: string;
}>

const BaseButton = React.forwardRef<HTMLButtonElement | HTMLAnchorElement, BaseButtonProps>((props, ref) => {

  const p = useProps(props);

  const s = useStore(() => ({
    handleClick: () => {
      p.onClick?.();
    },
    get commonAttr() {
      return {
        onClick: s.handleClick,
        className: joinClassName('BaseButton', p.className, p.disabled && 'disabled'),
        disabled: p.disabled,
        title: p.title,
        children: s.inner,
      }
    },
    get inner() {
      return <>
      { (p.label || p.Label || p.children) && <div>
          { p.Label }
          { p.label }
          { p.children }
        </div>}
      </>
    }
  }));

  const dataAttributes = useDataAttributes(p);

  return <Observer children={() => (
    p.to ? <Link to={p.to} {...s.commonAttr} {...dataAttributes} ref={ref as MutableRefObject<HTMLAnchorElement>} /> :
      <button type="button" {...s.commonAttr} {...dataAttributes} ref={ref as MutableRefObject<HTMLButtonElement>} />
  )} />

})

export default BaseButton;