import React from 'react';

type IconToggleProps = {
  className?: string,
}

const IconToggle: React.FC<IconToggleProps> = props => {
  return <svg className={props.className} width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1L5 5L9 1" stroke="#0A1D2A" strokeWidth="2"/>
    </svg>
  
    
}

export default IconToggle;