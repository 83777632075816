import { action } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { useReportIndexContext } from '../../controllers/reportIndex.controller';
import { useRootContext } from '../../controllers/root/root.controller';
import { Country } from '../../models/makeCountry.model';
import { useProps, useStore } from '../../utils/mobx.utils';
import BaseCheckbox from '../BaseCheckbox/BaseCheckbox';
import './CountriesCheckboxList.scss';

interface CountriesCheckboxListProps {
  countries?: Country[],
  searchQuery?: string,
}

const CountriesCheckboxList: React.FC<CountriesCheckboxListProps> = props => {

  const { COUNTRIES } = useRootContext();

  const p = useProps(props);

  const reportIndex = useReportIndexContext();

  const s = useStore(() => ({
    get countries() {
      return COUNTRIES.countriesWithReports;
    },
    matchesSearch: (country: Country) => {
      return country.$.name?.toLowerCase().includes(p.searchQuery?.toLowerCase() ?? "") ?? false
    },
  }));

  return <Observer children={() => (
    <ul className="CheckboxList CountriesCheckboxList">
      {s.countries.sort((c1, c2) => (c1.$.name! > c2.$.name! ? 1 : -1)).map(country =>
        (!p.searchQuery || s.matchesSearch(country)) ?
          <li className="AnimateEntry" key={country.$.id}>
            <BaseCheckbox
              getter={() => reportIndex.countryFilterIds.includes(country.$.id!) || reportIndex.countryFilterNames.includes(country.$.name!)}
              setter={action(() => reportIndex.updateCountryFilter(country))}
              Label={<>{country.$.name}</>}
            />
          </li> :
          ""
      )}
    </ul>
  )} />

}

export default CountriesCheckboxList;