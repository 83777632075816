import { action, observable, reaction } from "mobx";
import { RootController } from "./root.controller";

export const makeStocksController = () => {
  const s = observable({
    ROOT: null as RootController | null,
    get stocks() {
      return s.ROOT?.LOCALDB.data.banks;
    },
    reset: () => {
    },
    getAllStocks: async () => {
      await s.ROOT?.API.getMany('/api/banks', 'banks');
    },
    init: action((root: RootController) => {
      s.ROOT = root;
      reaction(
        () => s.ROOT?.AUTH.isAuthenticated,
        isAuthenticated => isAuthenticated && s.getAllStocks(),
        { fireImmediately: true }
      )
    })
  })
  return s;

}

export type StocksController = ReturnType<typeof makeStocksController>;
