import { observable } from "mobx";
import moment from "moment";
import { Nullable } from "../@types/base.types";
import { formatSearchString, formatString } from "../utils/string.utils";
import { CategorySnapshot } from "./makeCategory.model";
import { CountrySnapshot } from "./makeCountry.model";
import { ProductSnapshot } from "./makeProduct.model";
import { StockSnapshot } from "./makeStock.model";

export const makeReportSnapshot = () => ({
  id: null as null | number | string,
  title: '',
  subtitle: '',
  description: '',
  path: '',
  intro_description: '',
  video_path: '' as string | null,
  previewImageSrc: '' as string | null,
  created_at: '' as string | null,
  timeUpdated: '' as string | null,
  country: '' as Nullable<string>,
  countries: [] as CountrySnapshot[],
  status: '' as string,
  categories: [] as CategorySnapshot[],
  products: [] as ProductSnapshot[],
  banks: [] as StockSnapshot[],
  is_accessible: false as boolean,
  is_on_focus_list: 0 as number,
  cover_image_path: '' as Nullable<string>,
  cover_image_url: '' as Nullable<string>,
})

export type ReportSnapshot = ReturnType<typeof makeReportSnapshot>;

export const makeReport = (snapshot?: Partial<ReportSnapshot>) => {

  const s = observable({
    $: snapshot ?? makeReportSnapshot(),
    get id() {
      return s.$.id;
    },
    get momentCreated() {
      return moment(s.$.created_at)
    },
    get searchString() {
      return formatSearchString(s.$.title);
    },
    get formattedTitle() {
      return formatString(s.$.title)
    },
    get formattedSubTitle(){
      return formatString(s.$.subtitle)
    }
  });

  return s;

}

export type Report = ReturnType<typeof makeReport>;

