import { flow } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useRootContext } from '../../controllers/root/root.controller';
import joinClassName from '../../utils/className.utils';
import { reportError } from '../../utils/errors.utils';
import { useStore } from '../../utils/mobx.utils';
import { validateEmail } from '../../validators/email.validator';
import BaseButton from '../BaseButton/BaseButton';
import BaseCheckbox from '../BaseCheckbox/BaseCheckbox';
import BaseInput from '../BaseInput/BaseInput';
import BaseSpacer from '../BaseSpacer/BaseSpacer';
import CarraighillLogo from '../CarraighillLogo/CarraighillLogo';
import Dialog from '../Dialog/DialogOld';
import './LoginForm.scss';

interface LoginFormProps {}

const LoginForm: React.FC<LoginFormProps> = props => {

  const { AUTH, NAVIGATOR, REPORTS } = useRootContext();
  const [state, setState] = useState('start');
  const [errorMessage, setErrorMessage] = useState('');

  const s = useStore(() => ({
    form: {
      'email': '',
      'password': '',
      'rememberMe' : false
    },

    isSubmitting: false,

    get emailIsValid() {
			return validateEmail(s.form.email) === true;
    },

    get canSubmit() {
      return s.emailIsValid && s.form.password && !s.isSubmitting;
    },

    submit: async () => await flow(function* () {
      s.isSubmitting = true;
      try {
        yield AUTH.login(s.form);
        if(REPORTS.redirectedReportURL !== ''){
          NAVIGATOR.redirectTo(REPORTS.redirectedReportURL);
          REPORTS.redirectedURL = '';
        }
        AUTH.updateActivityEndpoint();
      } catch (e: any) {
        setState('Failure');
        reportError(e);
        if (e.response?.data?.errors ?? false) {
          const errorMessageKey = Object.keys(e.response.data.errors)[0];
          setErrorMessage(e.response.data.errors[errorMessageKey]);
        } else {
          setErrorMessage('Unexpected error occurred while logging in!')
        }
      } finally {
        s.isSubmitting = false;
      }
    })(),
    errorHandler(){
      setState('');
    }

  }))
  return <Observer children={() => (
    <div className="LoginForm">
      <CarraighillLogo />
      <form>
        <BaseInput
          form={s.form}
          field="email"
          type="email"
          placeholder="Enter your email..."
          autoComplete="email"
          onEnter={s.submit}
        />
        <BaseSpacer />
        <BaseInput
          form={s.form}
          field="password"
          type="password"
          placeholder="Your password"
          autoComplete="password"
          onEnter={s.submit}
        />
        <BaseSpacer size="md" />
        <div className="LoginFormSubmission">
          <BaseCheckbox field="rememberMe" form={s.form} Label="Remember Me" />
          <BaseButton
            title="Log In"
            Label="Log in"
            disabled={!s.canSubmit}
            onClick={s.submit}
          />
        </div>
        { AUTH.isAuthenticated && <Redirect to="/reports" /> }
      </form>
      {state === 'Failure' && <Dialog
        className={joinClassName('ErrorRenderer')}
        dialogHeading="Failed to Log In"
        dialogMessage={errorMessage}
        onClickHandler={s.errorHandler}
      />}
      { props.children }
    </div>
  )} />
}
export default LoginForm;