import { reaction } from 'mobx';
import React from 'react';
import { useOnMount } from '../../hooks/lifecycle.hooks';
import { useControllers } from '../../hooks/useRootController.hook';
import { last } from '../../utils/ramdaEquivalents.utils';

type TitleManagerProps = {}

const TitleManager: React.FC<TitleManagerProps> = props => {
	const { COMMON, NAVIGATOR } = useControllers();
	useOnMount(() => {
		return reaction(
			() => last(NAVIGATOR.titleStack)?.title,
			title => {
				const newTitle = title || COMMON.app.displayName;
				document.title = newTitle;
			}
		)
	})
	return null;
}

export default TitleManager;