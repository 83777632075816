import { action, flow } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import AccountManagementForm from '../../components/AccountInfoForm/AccountInfoForm';
import AppNavBar from '../../components/AppNavBar/AppNavBar';
import BaseButton from '../../components/BaseButton/BaseButton';
import Dialog from '../../components/Dialog/DialogOld';
import PageContent from '../../components/PageContent/PageContent';
import PageContentHeader from '../../components/PageContentHeader/PageContentHeader';
import WindowTitle from '../../components/WindowTitle/WindowTitle';
import { useRootContext } from '../../controllers/root/root.controller';
import { useOnMount } from '../../hooks/lifecycle.hooks';
import { makeUserSnapshot } from '../../models/makeUser.model';
import joinClassName from '../../utils/className.utils';
import { reportError } from '../../utils/errors.utils';
import { useStore } from '../../utils/mobx.utils';
import { validateEmail } from '../../validators/email.validator';
import './PageAccountManagement.scss';

type PageAccountManagementProps = {
  autoOpen?: boolean,
}

const PageAccountManagement: React.FC<PageAccountManagementProps> = props => {
  const { AUTH } = useRootContext();
  const [userDataUpdated, setState] = useState('');
  const [ErrorMessage, setErrorMessage] = useState('');

  const s = useStore(() => ({
    form : makeUserSnapshot(),
    open: props.autoOpen ?? false,
    submitForm: action(() => {
      s.open = !s.open;
    }),
    get hasValidEmail() {
			return validateEmail(s.form.email) === true;
		},
    get canSubmit() {
      return Boolean(s.form.email && s.form.first_name && s.form.last_name);
    },
    submit: async () => await flow(function* () {
      try {
        yield AUTH.updateUserData(s.form);
        setState('dataUpdated');
      } catch (e: any) {
        setState('dataUpdateFailed');
        const errorMessageKey = Object.keys(e.response.data.errors)[0];
        setErrorMessage(e.response.data.errors[errorMessageKey]);
        reportError(e);
      } finally {
      }
    })(),
    get userData(){
      return AUTH.user;
    },
    dismiss(){
      setState('');
    },

  }));
  useOnMount(() => {
    action(async () => {
      s.form.username = AUTH.user?.$.username!;
      s.form.first_name = AUTH.user?.$.first_name!;
      s.form.last_name = AUTH.user?.$.last_name!;
      s.form.phone = AUTH.user?.$.phone!;
      s.form.email = AUTH.user?.$.email!;
      s.form.companyName = AUTH.user?.$.companyName!;
      s.form.has_disabled_email_notifications = AUTH.user?.$.has_disabled_email_notifications!;
      s.form.id = AUTH.user?.id!;
      // Object.assign(s.form, AUTH.user?.$);
      // console.log(toJS(AUTH.user?.$));
    })()
  })
  return <Observer children={() => (
    <div className="PageAccountManagement">
      <AppNavBar />
      <WindowTitle title="Manage Your Account" />
      <div className="PageAccountManagementContent">
        <PageContent>
          <div className="PageAccountManagementHeader">
            <PageContentHeader
              Title="Account"
              AfterTitle="Manage your Carraighill Account."
            />
            <div>
              <BaseButton disabled={!s.canSubmit} title="Save Changes" Label="Save Changes" onClick={s.submit}/>
            </div>
          </div>
          <AccountManagementForm form={s.form}/>
        </PageContent>
      </div>
      {userDataUpdated === 'dataUpdateFailed' && <Dialog
        className={joinClassName('ErrorRenderer')}
        dialogHeading="Failed to Log In"
        dialogMessage={ErrorMessage}
        onClickHandler={s.dismiss}
      />}
      {userDataUpdated === 'dataUpdated' && <Dialog
        dialogHeading="Account Information Updated Successfully"
        // dialogMessage={'Account Information Updated'}
        onClickHandler={s.dismiss}
      />}
    </div>
  )} />
}

export default PageAccountManagement;