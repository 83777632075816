import { Observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { useRootContext } from "../../controllers/root/root.controller";
import { useOnMount } from "../../hooks/lifecycle.hooks";

type HoldReportInfoProps = {
  reportID?: string,
}

const HoldReportInfo: React.FC<HoldReportInfoProps> = props => {
  // const {pathname} = useLocation();
  const { id } : any = useParams();
  const { AUTH, NAVIGATOR, REPORTS } = useRootContext();
  useOnMount(() => {
    REPORTS.redirectedURL = "reports/"+id;
    if(AUTH.isAuthenticated)
      NAVIGATOR.redirectTo('/reports/'+id);
    else
      NAVIGATOR.redirectTo('/login');
  })

  return <Observer children={() => (
    <p></p>
  )} />
}
export default HoldReportInfo;