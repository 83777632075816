import { Validator } from "../@types/validators.types";

export const validateEmail: Validator = (
  value?: string
) => {
  // const isValid = value?.split('').filter(x => x === '@').length === 1 && !value?.match(/[<>,;()|:\\/]/);
  const isValid = !!value && /^.+@.+\..+$/.test(value);
  const message = value ? (
    `${value?.toString()} is not a valid email address.`
  ) : (
    'Please provide a valid email address.'
  );
  return isValid || {
    isValid,
    message
  }
}