import React from 'react';
import { ReportAccessibleIconType } from '../ReportDownloader/ReportDownloader';

type PlayVideoIconProps = {
  type?: ReportAccessibleIconType,
}

const PlayVideoIcon: React.FC<PlayVideoIconProps> = props => {
  switch (props.type) {
    case ReportAccessibleIconType.disallow:
      return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M5 6H6.29289L5 7.29289V6ZM5.70711 8L7.70711 6H9.79289L7.79289 8H5.70711ZM9.20711 8H11.7929L13.7929 6H11.2071L9.20711 8ZM15.7929 8H13.2071L15.2071 6H17.7929L15.7929 8ZM17.2071 8H19V6.20711L17.2071 8ZM5 9V17H14V13.1888V12L15 12.6429L20.0754 15.9056L21 16.5L20.0754 17.0944L15 20.3571L14 21V19.8112V18H5H4V17V6V5H5H19H20V6V14.5714L19 13.9286V9H5ZM19.1507 16.5L15 13.8317V19.1683L19.1507 16.5Z" fill="currentColor" />
        <circle cx="16.5" cy="16.5" r="6" stroke="currentColor" />
        <line x1="11.8131" y1="20.4798" x2="20.4798" y2="11.8131" stroke="currentColor" />
      </svg>
    case ReportAccessibleIconType.allow:
    default:
      return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M5 6H6.29289L5 7.29289V6ZM5.70711 8L7.70711 6H9.79289L7.79289 8H5.70711ZM9.20711 8H11.7929L13.7929 6H11.2071L9.20711 8ZM15.7929 8H13.2071L15.2071 6H17.7929L15.7929 8ZM17.2071 8H19V6.20711L17.2071 8ZM5 9V17H14V13.1888V12L15 12.6429L20.0754 15.9056L21 16.5L20.0754 17.0944L15 20.3571L14 21V19.8112V18H5H4V17V6V5H5H19H20V6V14.5714L19 13.9286V9H5ZM19.1507 16.5L15 13.8317V19.1683L19.1507 16.5Z" fill="currentColor" />
      </svg>
  }



}

export default PlayVideoIcon;