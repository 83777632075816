import { action, observable, reaction } from "mobx";
import { RootController } from "./root.controller";

export const makeProductsController = () => {
  const s = observable({
    ROOT: null as RootController | null,
    get products() {
      return s.ROOT?.LOCALDB.data.products;
    },
    reset: () => {
    },
    getAllProducts: async () => {
      await s.ROOT?.API.getMany('/api/products', 'products');
    },
    init: action((root: RootController) => {
      s.ROOT = root;
      reaction(
        () => s.ROOT?.AUTH.isAuthenticated,
        isAuthenticated => isAuthenticated && s.getAllProducts(),
        { fireImmediately: true }
      )
    })
  })
  return s;

}

export type ProductsController = ReturnType<typeof makeProductsController>;
