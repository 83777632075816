import { Nillable } from "../@types/base.types";
import { Size } from "../constants/sizes.constants";


export function getCSSCustomProperty(name: string, element: Nillable<HTMLElement> = document.documentElement) {      // const styles = window.getComputedStyle(document.documentElement);
  if (!element) return null;
  const styles = window.getComputedStyle(element);
  return styles.getPropertyValue(name);
}
export function setCSSCustomProperty(name: string, value: string, element: Nillable<HTMLElement> = document.documentElement) {
  element?.style.setProperty(name, value);
}
export function removeCSSCustomProperty(name: string, element: Nillable<HTMLElement> = document.documentElement) {
  element?.style.removeProperty(name);
}
export function size(string: Size) {
  return `var(--app-s-${string})`;
}
export function isStandardSize(inputString: any): inputString is Size {
  return ['xs', 'sm', 'md', 'lg', 'xl'].includes(inputString);
}
export function cssStyle(colorName: string, opacity: number, lightnessOffset: number, saturationOffset: number, hueOffset: number) {
  
}