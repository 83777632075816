import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Link } from 'react-router-dom';
import CarraighillLogo from '../CarraighillLogo/CarraighillLogo';
import LogoutIcon from '../LogoutIcon/LogoutIcon';
import ProfileIcon from '../ProfileIcon/ProfileIcon';
import './AppNavBar.scss';

type AppNavBarProps = {
  autoOpen?: boolean,

}

const AppNavBar: React.FC<AppNavBarProps> = props => {
  
  return <Observer children={() => (
    <div className="AppNavBar">
      <div className="AppNavBarInner">
        <Link to="/reports" className="AppNavBarLogoLink">
          <CarraighillLogo />
        </Link>
        <div className="AppNavBarIconList">
          <Link to="/account" className="AppNavBarIconLink" >
            <ProfileIcon />
          </Link>
          <Link to="/logout" className="AppNavBarIconLink">
            <LogoutIcon />
          </Link>
      </div>
      </div>
    </div>
  )} />
}

export default AppNavBar;