import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Link } from 'react-router-dom';
import { Report } from '../../models/makeReport.model';
import { useProps } from '../../utils/mobx.utils';
import { getDateFromUTC } from '../../utils/time.utils';
import ReportDownloader, { DownloaderViewType } from '../ReportDownloader/ReportDownloader';
import "./SearchListEntry.scss";

type SearchListEntryProps = {
  report: Report,
}

const SearchListEntry: React.FC<SearchListEntryProps> = props => {

  const p = useProps(props);

  return <Observer children={() => (
    <div className="SearchListEntry">
      <Link to={`/reports/${p.report.$.id}`}>
        <div className="SearchListEntryContent">
          <div className="SearchListEntryHeader">
            <h5 className="SearchListEntryPublishedDate">{getDateFromUTC(props.report.$.created_at!)}</h5>
            <h4 className="SearchListEntryTitle">{p.report.$.title}</h4>
            {p.report.$.subtitle! && <span className="SearchListEntrySubTitle">{p.report.$.subtitle}</span>}
          </div>
          <ReportDownloader report={p.report} downloaderViewType={DownloaderViewType.SEARCH_LIST_ENTRY} />
        </div>
      </Link>
    </div>
  )} />
}

export default SearchListEntry;