import { action, reaction } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { isBetaSite, isLocalhost, isStagingSite } from '../../env';
import { useOnMount } from '../../hooks/lifecycle.hooks';
import { useControllers } from '../../hooks/useRootController.hook';
import { keepTruthy, moveToEndOfArray } from '../../utils/array.utils';
import joinClassName from '../../utils/className.utils';
import { IS_DEV } from '../../utils/env.utils';
import { generateUuid } from '../../utils/id.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import tick from '../../utils/waiters.utils';
import './WindowTitle.scss';

interface WindowTitleProps {
  className?: string,
  title?: string,
  visible?: boolean,
}

const WindowTitle: React.FC<WindowTitleProps> = props => {

  const p = useProps(props);

  const { COMMON, NAVIGATOR } = useControllers();

  const s = useStore(() => ({
    id: generateUuid(),
    get appName() {
      return COMMON.app.displayName;
    },
    get titleStack() {
      return NAVIGATOR.titleStack
    },
    get title() {
      return p.title;
    },
    setTitle: action((title?: string) => {
      const newTitle = keepTruthy([
        (isLocalhost || IS_DEV) ? '[DEV]' : '',
        isBetaSite ? '[BETA]' : '',
        isStagingSite ? '[STAGING]' : '',
        title,
        s.appName,
      ]).join(' • ');
      const existingRecord = s.titleStack.find(t => t.id === s.id);
      if (existingRecord) {
        existingRecord.title = newTitle;
        moveToEndOfArray(s.titleStack, existingRecord);
      } else {
        s.titleStack.push({ id: s.id, title: newTitle });
      }
    }),
  }));

  useOnMount(() => {
    const disposeReaction = reaction(
      () => s.title,
      () => {
        (async () => {
          await tick();
          s.setTitle(s.title);
        })();
      },
      { fireImmediately: true }
    );
    return action(() => {
      s.setTitle();
      disposeReaction();
      const index = s.titleStack.findIndex(i => i.id === s.id);
      s.titleStack.splice(index, 1);
    })
  });

  return <Observer children={() => (
    <h1 className={
      joinClassName('WindowTitle', p.className)
    } data-visible={p.visible ? 'true' : 'false'}>{s.title}</h1>
  )} />

}

export default WindowTitle;