import { getValueOfKey } from "./utils/object.utils";

const LocalAPIOverrideStorageKey = "LOCAL_API_OVERRIDE";

const API_URL_MAP = Object.freeze({
  /** the official site */
  "app.carraighill.com": "https://api.carraighill.com",
  "www.app.carraighill.com": "https://api.carraighill.com",
  /** staging/non public, a testing platform the next version to be released. */
  "carraighill.axon.host": "https://carraighill-api.axon.host",
  "main.d1gllnonurt97c.amplifyapp.com": "https://staging.carraighill-api.com",
  "beta.carraighill.com": "https://staging.carraighill-api.com",
  "www.beta.carraighill.com": "https://staging.carraighill-api.com",
  /** cross-device internal testing use */
  // 'localhost': localStorage.getItem(LocalAPIOverrideStorageKey) ?? 'https://carraighill-api.axon.host',
  localhost:
    localStorage.getItem(LocalAPIOverrideStorageKey) ??
    "https://carraighill.test",
  // 'localhost': localStorage.getItem(LocalAPIOverrideStorageKey) ?? 'https://carraighill-novus.test',
  // 'localhost': localStorage.getItem(LocalAPIOverrideStorageKey) ?? 'https://carraighill.eu.ngrok.io',
  default: "https://api.carraighill.com",
});

const { hostname } = window.location;

export const isLocalhost = hostname === "localhost";
export const isBetaSite = hostname === "beta.carraighill.com";
export const isStagingSite = hostname === "carraighill.axon.host";
export const isOfficialSite = hostname === "app.carraighill.com";
export const isTrainingSite = hostname === "carraighill.axon.host";

export const API_HOST = (
  getValueOfKey<any>(hostname, API_URL_MAP) || API_URL_MAP.default
).replace(/\/+$/, "");
