import { Observer } from 'mobx-react-lite';
import React from 'react';
import AppFooter from '../../components/AppFooter/AppFooter';
import ForgotPasswordForm from '../../components/ForgotPasswordForm/ForgotPasswordForm';
import LoginBackgroundImage from '../../components/LoginBackgroundImage/LoginBackgroundImage';
import WindowTitle from '../../components/WindowTitle/WindowTitle';
import './PageForgotPassword.scss';


type PageForgotPasswordProps = {}

const PageForgotPassword: React.FC<PageForgotPasswordProps> = props => {
  return <Observer children={() => (
    <div className="AuthPage">
      <div className="AuthPageContainer">
        <WindowTitle title="Forgot Password" />
        <span className="LoginBackgroundImage"><LoginBackgroundImage /></span>
        <main className="PageForgotPassword">
          <div className="ForgotPasswordContent">
            <ForgotPasswordForm />
          </div>
        </main>
      </div>
      <footer>
        <AppFooter />
      </footer>
    </div>
  )} />
}

export default PageForgotPassword;