import { flow, observable } from "mobx";
import { User, UserSnapshot } from "../../models/makeUser.model";
import { AXIOS } from "../../utils/axios.utils";
import { reportError } from "../../utils/errors.utils";
import { RootController } from "./root.controller";

export const TOKEN_STORAGE_KEY = "TOKEN";

export const makeAuthController = () => {
  const makeHeaders = () => {
    const token = localStorage.getItem(TOKEN_STORAGE_KEY);
    const baseHeaderPartial = {
      Accept: "application/json",
      common: { "X-Requested-With": "XMLHttpRequest" },
    };
    const headers = token
      ? {
          Authorization: `Bearer ${token}`,
          ...baseHeaderPartial,
        }
      : baseHeaderPartial;
    return headers;
  };
  const s = observable({
    ROOT: null as RootController | null,
    ready: false,
    user: null as User | null,
    resetPasswordTime: 0 as number,

    login: async (form: { email: string; password: string }) =>
      await flow(function* () {
        const response = yield AXIOS.post("/api/login", form);
        localStorage.setItem(
          TOKEN_STORAGE_KEY,
          response.data.token.split("|")[1]
        );
        return yield s.getCurrentUserData();
      })(),

    updateUserData: async (form: UserSnapshot) =>
      await flow(function* () {
        const headers = makeHeaders();
        // eslint-disable-next-line
        const response = yield AXIOS.patch("/api/user", form, { headers });
        return yield s.getCurrentUserData();
      })(),

    updatePassword: async (form: {
      currentPassword: string;
      newPassword: string;
      newPasswordConfirm: string;
    }) =>
      await flow(function* () {
        const headers = makeHeaders();
        // eslint-disable-next-line
        const response = yield AXIOS.patch("/api/user-password", form, {
          headers,
        });
      })(),

    resetPassword: async (form: {
      token: string;
      email: string;
      password: string;
      password_confirmation: string;
    }) =>
      await flow(function* () {
        const headers = { Accept: "application/json" };
        // eslint-disable-next-line
        const response = yield AXIOS.post("/api/auth/reset-password", form, {
          headers,
        });
        return response;
      })(),

    getCurrentUserData: async () =>
      await flow(function* () {
        const user: User = yield s.ROOT?.API.getOne("/api/user", "users");
        s.user = user;
        return true;
      })(),

    logout: () => {
      s.reset();
    },

    get isAuthenticated() {
      return !!s.user;
    },

    checkExistingTokenValidity: async () => {
      const token = localStorage.getItem(TOKEN_STORAGE_KEY);
      if (token) {
        try {
          await s.getCurrentUserData();
          return true;
        } catch (e) {
          reportError(e);
          return false;
        }
      } else {
        return false;
      }
    },

    updateActivityEndpoint: async () => {
      const headers = makeHeaders();
      // eslint-disable-next-line
      const loginActivity = AXIOS.post(`api/activity/login`, [], {
        headers,
      });
    },

    reset: () => {
      s.user = null;
      localStorage.removeItem(TOKEN_STORAGE_KEY);
      s.ROOT?.LOCALDB.reset();
    },

    init: (root: RootController) =>
      flow(function* () {
        s.ROOT = root;
        const onError = () => {
          s.reset();
        };
        try {
          const result: boolean = yield s.checkExistingTokenValidity();
          if (!result) onError();
        } catch (e) {
          onError();
        } finally {
          s.ready = true;
        }
      })(),
  });

  return s;
};

export type AuthController = ReturnType<typeof makeAuthController>;
