import { Observer } from "mobx-react-lite";
import React from "react";
import { Redirect } from "react-router-dom";
import { useRootContext } from "../../controllers/root/root.controller";
import { AXIOS } from "../../utils/axios.utils";
import joinClassName from "../../utils/className.utils";
import { reportError } from "../../utils/errors.utils";
import { useStore } from "../../utils/mobx.utils";
import { validateEmail } from "../../validators/email.validator";
import BaseButton from "../BaseButton/BaseButton";
import BaseInput from "../BaseInput/BaseInput";
import BaseSpacer from "../BaseSpacer/BaseSpacer";
import CarraighillLogo from "../CarraighillLogo/CarraighillLogo";
import Dialog from "../Dialog/DialogOld";
import "./ForgotPasswordForm.scss";

interface ForgotPasswordFormProps {}

const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = (props) => {
  const { NAVIGATOR, AUTH, UI } = useRootContext();

  const s = useStore(() => ({
    isProcessing: false,
    error: {
      show: false as boolean,
      msg: "" as string,
    },
    form: {
      email: "" as string,
    },
    get emailIsValid() {
      return validateEmail(s.form.email) === true;
    },
    get canSubmit() {
      return s.emailIsValid;
    },
    submit: async () => {
      try {
        s.isProcessing = true;
        await AXIOS.get("/api/auth/reset-password?email=" + s.form.email)
          .then(() => {
            UI.DIALOG.success({
              heading:
                "Password reset link has been sent to your email address!",
            });
          })
          .finally(() => {
            s.isProcessing = false;
          });
      } catch (e: any) {
        s.error.show = true;
        reportError(e);
        if (e.response?.data?.message ?? false) {
          const errorMessageKey = e.response.data.message;
          s.error.msg = errorMessageKey;
        } else {
          s.error.msg = "Unexpected error occurred while logging in!";
        }
      }
    },

    resetErrorState() {
      s.error.show = false;
      s.error.msg = "";
    },
  }));

  return (
    <Observer
      children={() => (
        <div className="ForgotPasswordForm">
          <CarraighillLogo />
          <form>
            <h3>Reset Password</h3>
            <BaseSpacer size="lg" />
            <BaseInput
              form={s.form}
              field="email"
              type="email"
              Label={<>E-mail address</>}
              placeholder="Enter your email..."
              autoComplete="email"
              onEnter={s.submit}
            />
            <BaseSpacer />
            <div className="ForgotPasswordFormSubmission">
              <BaseButton
                title="Back to Log in"
                label="Back to Log in"
                onClick={() => NAVIGATOR.redirectTo("/login")}
              />
              <BaseButton
                title="Send Password Reset Link"
                label="Send Password Reset Link"
                disabled={s.isProcessing || !s.canSubmit}
                onClick={s.submit}
              />
            </div>
            {AUTH.isAuthenticated && <Redirect to="/reports" />}
          </form>
          {s.error.show && (
            <Dialog
              className={joinClassName("ErrorRenderer")}
              dialogHeading="Failed to send password reset link."
              dialogMessage={s.error.msg}
              onClickHandler={s.resetErrorState}
            />
          )}
          {props.children}
        </div>
      )}
    />
  );
};
export default ForgotPasswordForm;
