import { action, flow, observable } from "mobx";
import resolveAfter from "../../utils/delays.utils";
import { RootController } from "./root.controller";

export const makeNavigatorController = () => {
  const s = observable({
    ROOT: null as RootController | null,
    shouldRedirectTo: '',
    redirectTo: flow(function * (path: string) {
      s.shouldRedirectTo = path;
      yield resolveAfter();
      s.shouldRedirectTo = '';
    }),
    reset: () => {
    },
    init: action((root: RootController) => {
      s.ROOT = root;
    }),
    titleStack: [] as { id: string, title: string }[],
  })
  return s;

}

export type NavigatorController = ReturnType<typeof makeNavigatorController>;
