import React from 'react';
import './LogoutIcon.scss';

type LogoutIconProps = {
}

const LogoutIcon: React.FC<LogoutIconProps> = props => {
  return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M11.9999 3C11.7237 3 11.4999 3.22386 11.4999 3.5L11.4999 12.3421C11.4999 12.6182 11.7237 12.8421 11.9999 12.8421C12.276 12.8421 12.4999 12.6182 12.4999 12.3421V3.5C12.4999 3.22386 12.276 3 11.9999 3ZM5.08278 10.5715C5.32231 9.36726 5.87143 8.24938 6.67219 7.3262C6.85313 7.1176 6.85465 6.80211 6.65938 6.60685C6.46412 6.41159 6.14623 6.41061 5.96346 6.61761C5.0238 7.68185 4.38012 8.97815 4.102 10.3764C3.79129 11.9384 3.95075 13.5576 4.56024 15.029C5.16972 16.5004 6.20185 17.7581 7.5261 18.6429C8.85035 19.5277 10.4072 20 11.9999 20C13.5926 20 15.1495 19.5277 16.4737 18.6429C17.798 17.7581 18.8301 16.5004 19.4396 15.029C20.049 13.5576 20.2085 11.9384 19.8978 10.3764C19.6197 8.97815 18.976 7.68185 18.0363 6.61761C17.8536 6.41061 17.5357 6.41159 17.3404 6.60685C17.1452 6.80211 17.1467 7.1176 17.3276 7.3262C18.1284 8.24938 18.6775 9.36726 18.917 10.5715C19.1891 11.9395 19.0495 13.3576 18.5157 14.6463C17.9819 15.935 17.0779 17.0365 15.9181 17.8114C14.7583 18.5864 13.3948 19 11.9999 19C10.605 19 9.24147 18.5864 8.08167 17.8114C6.92187 17.0365 6.01792 15.935 5.48412 14.6463C4.95032 13.3576 4.81066 11.9395 5.08278 10.5715Z" fill="currentColor" />
  </svg>
}

export default LogoutIcon;