import { eqByFn, isNil } from "./ramdaEquivalents.utils";
import { isArray } from "./typeChecks.utils";

const Pluralize = require('pluralize');

export type StringTransformerInput = string | number | null | undefined;

export function capitalizeFirstLetter(input?: StringTransformerInput) {
  const string = '' + input || '';
  return string.replace(/^./, string[0].toUpperCase())
}
export function normalizeString(input?: StringTransformerInput) {
  return (('' + input) || '').normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}
export const formatSearchString = (input?: StringTransformerInput) => {
  return normalizeString(input).toLowerCase().replace(/[\s\n]/gi, '');
}
export const formatString = (input?: StringTransformerInput) => {
  return normalizeString(input).toLowerCase().replace(/[\s\n]/gi, '');
}
export function autoPluralize(
  x: number | any[],
  singularString: string,
  zeroString?: string,
  pluralString?: string,
  doNotAutoDisplayNumber?: boolean
) {
  const amount = isArray(x) ? x.length : x;
  if (!amount && zeroString) return zeroString;
  if (amount === 1) {
    if (singularString.includes('%d')) {
      return singularString.replace(/%d/, '1');
    }
    if (doNotAutoDisplayNumber) return singularString;
    return '1 ' + singularString;
  } else {
    const _pluralString = pluralString || pluralize(singularString);
    if (_pluralString) {
      if (_pluralString.includes('%d')) {
        return _pluralString.replace(/%d/, amount + '');
      }
      if (doNotAutoDisplayNumber) return _pluralString;
      return amount + ' ' + _pluralString;
    }
  }
  return amount;
}
export function pluralize(input?: StringTransformerInput) {
  if (!input) return '';
  return Pluralize.plural(input);
}

export function arrayToString(arr: string[], delimiter?: string) {
  return arr.map(s => (!!s && s)).join(delimiter ?? "")
}

export const equalByString = eqByFn(String);

export const toLowerCase = (s: string) => isNil(s) ? '' : s.toLowerCase();

export const equalByLowerCase = eqByFn(toLowerCase);
