import { Observer } from 'mobx-react-lite';
import React from 'react';
import AppFooter from '../../components/AppFooter/AppFooter';
import LoginBackgroundImage from '../../components/LoginBackgroundImage/LoginBackgroundImage';
import LoginPageContent from '../../components/LoginPageContent/LoginPageContent';
import WindowTitle from '../../components/WindowTitle/WindowTitle';
import './PageLogin.scss';


type PageLoginProps = {}

const PageLogin: React.FC<PageLoginProps> = props => {
  return <Observer children={() => (
    <div className="AuthPage">
      <div className="AuthPageContainer">
        <WindowTitle title="Log In" />
        <span className="LoginBackgroundImage"><LoginBackgroundImage /></span>
        <main className="PageLogin">
          <LoginPageContent />
        </main>
      </div>
      <footer>
        <AppFooter />
      </footer>
    </div>
  )} />
}

export default PageLogin;