import { action } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { useOnMount } from '../../hooks/lifecycle.hooks';
import { useControllers } from '../../hooks/useRootController.hook';
import joinClassName from '../../utils/className.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import { arrayToString } from '../../utils/string.utils';
import { isArray } from '../../utils/typeChecks.utils';
import tick from '../../utils/waiters.utils';
import BaseButton from '../BaseButton/BaseButton';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import VideoCloseIcon from './VideoCloseIcon';
import './VideoWrapper.scss';

type VideoWrapperProps = {
  id: string,
  className?: string,
  loop?: boolean,
  muted?: boolean,
  poster?: string,
  posterWebp?: string,
  autoPlay?: boolean,
  controls?: boolean,
  preload?: HTMLVideoElement['preload'],
  playsInline?: boolean,
  width?: number,
  height?: number,
  src?: string | string[],
  onlyLoadWhenVisible?: boolean,
  rounded?: boolean,
  fullscreenOnly?: boolean,
  hideVideo?: (e: React.MouseEvent) => unknown;
}

const VideoWrapper: React.FC<VideoWrapperProps> = props => {

  const { UI } = useControllers();

  const p = useProps(props);

  const s = useStore(() => ({
    src: p.onlyLoadWhenVisible
      ? []
      : isArray(p.src)
        ? p.src
        : !!p.src
          ? [p.src]
          : [],
    isFromVideoPlatform(videoSrc: string) {
      return isYoutube(videoSrc) || isVimeo(videoSrc);
    },
    getIframeEmbedLink(videoSrc: string) {
      if (isVimeo(videoSrc)) return getVimeoVideoLink(videoSrc);
      if (isYoutube(videoSrc)) return getYoutubeVideo(videoSrc);

      // return getYoutubeVideo(videoSrc)
    },
    isAllFromVideoPlatform() {
      for (let videoSrc of s.src) {
        if (!s.isFromVideoPlatform(videoSrc)) return false;
      }
      return true;
    },

    isLoading: true,
    stopLoadingIndicator: action(() => {
      s.isLoading = false;
    })
  }));

  const getVimeoVideoLink = (videoPath: string) => {
    const match = videoPath.match(/^https:\/\/vimeo.([a-zA-Z]+)\/(\d+)$/)
    const topLevelDomain = match?.[1]
    const vimeoVideoId = match?.[2]
    return `https://player.vimeo.${topLevelDomain}/video/${vimeoVideoId}?autoplay=${!!p.autoPlay ? "1" : "0"}`
  }

  const getYoutubeVideo = (videoPath: string) => {
    const match = videoPath.match(/^https:\/\/youtube.([a-zA-Z]+)\/(\d+)$/)
    const topLevelDomain = match?.[1]
    const youtubeVideoId = match?.[2]

    // const topLevelDomain = "com"
    // const youtubeVideoId = "5qap5aO4i9A"

    return `https://www.youtube.${topLevelDomain}/embed/${youtubeVideoId}?autoplay=${!!p.autoPlay ? "1" : "0"}&modestbranding=1`
  }

  const isYoutube = (videoPath: string) => {
    return videoPath.startsWith("https://youtube.")
  }
  const isVimeo = (videoPath: string) => {
    return videoPath.startsWith("https://vimeo.")
  }

  useOnMount(() => {
    (action(async () => {
      // auto remove indicator after some time.
      // iframe onLoad event not fired in Chrome in this React version.
      await tick(5000);
      s.stopLoadingIndicator()
    }))()
  })

  return <Observer children={() => (
    <div className={joinClassName("VideoWrapperContainer", p.fullscreenOnly && 'fullscreenOnly')}>
      {p.hideVideo &&
        <span onClick={p.hideVideo}>
          <BaseButton>
            <VideoCloseIcon /> {UI.fromTablet && <span>Close</span>}
          </BaseButton>
        </span>
      }
      {s.src.length !== 0 &&
        <div
          id={p.id}
          className={joinClassName('VideoWrapper', p.className, p.rounded && 'rounded')}>
          {s.isLoading && <LoadingIndicator />}
          {s.src && s.src.map((srcLink, i) => (
            s.isFromVideoPlatform(srcLink) &&
            <iframe key={srcLink} title={srcLink} className={joinClassName("IframeVideoEmbed")} src={s.getIframeEmbedLink(srcLink!)} width={p.width} height={p.height} frameBorder="0" allow={arrayToString(["fullscreen", p.autoPlay ? "autoplay" : ""], "; ")}>
              Your browser does not support iframes.
            </iframe>
          ))}
          {!s.isAllFromVideoPlatform() &&
            <video
              loop={p.loop ?? true}
              muted={p.muted ?? true}
              poster={p.poster}
              preload={p.preload ?? 'auto'}
              width={p.width}
              height={p.height}
              autoPlay={p.autoPlay ?? (p.onlyLoadWhenVisible ? true : false)}
              controls={p.controls ?? false}
              playsInline={p.playsInline ?? true}>
              {s.src && s.src.map((srcLink, i) => (
                !s.isFromVideoPlatform(srcLink) && <source src={srcLink} type="video/mp4" key={i} />
              ))}
            </video>
          }
        </div>
      }
    </div>
  )} />
}

export default VideoWrapper;