import { flow, makeAutoObservable, runInAction } from "mobx";
import { isFunction } from "util";
import { v4 as uuid } from 'uuid';
import { HasId } from "../@types/traits.types";
import { DialogController } from "../controllers/ui/dialog.controller";
import { ActionConfig, DialogConfig, DialogStatus, IDialog } from "../controllers/ui/ui.controller.types";
import { generateUuid } from "../utils/id.utils";
import tick from "../utils/waiters.utils";


export const makeDefaultDialogActions: () => ActionConfig[] = () => [
  {
    name: 'negative',
    label: 'Cancel',
    buttonClass: 'subtle',
    action: () => false,
  },
  {
    name: 'positive',
    label: 'OK',
    action: () => true,
  },
]

export default class Dialog implements IDialog {
  id = generateUuid();
  config: DialogConfig;
  status: DialogStatus = 'beforeOpen';
  controller: DialogController;
  promise: Promise<any>;
  actions: (ActionConfig & { id: string })[] = [];
  constructor(
    config: DialogConfig,
    controller: DialogController
  ) {
    const ref = this;
    this.config = config;
    if (!this.config.name) this.config.name = this.id;
    this.controller = controller;
    this.status = 'opened';
    this.promise = new Promise((resolve: (v: any) => void, reject) => {
      const defaultActions = makeDefaultDialogActions();
      const shouldAddDefaultActions: ActionConfig[] = this.config.defaultActions ? (
        this.config.defaultActions
          .map(name => defaultActions.find(i => i.name === name))
          .filter(i => !!i) as ActionConfig[]
      ) : (this.config.actions ? [] : defaultActions);
      const providedActions = [...shouldAddDefaultActions, ...(this.config.actions || [] as ActionConfig[])];
      providedActions.forEach(a => {
        a.id = uuid();
        a.waiting = false;
        const { action } = a;
        a.action = flow(function * () {
          try {
            let result: any;
            a.waiting = true;
            result = isFunction(action) ? yield action() : void 0;
            resolve(result);
          } catch (e) {
            reject(e);
          } finally {
            a.waiting = false;
            ref.close();
          }
        });
      })
      this.actions = providedActions as (ActionConfig & HasId)[];
    })
    makeAutoObservable(this);
  }
  get _closeFromUiStore() {
    return this.controller.dismiss;
  }
  close() {
    return new Promise<void | false>(async (resolve, reject) => {
      if (this.config.onBeforeClose) {
        const result = await this.config.onBeforeClose();
        if (!result) {
          resolve(false);
          return;
        }
      }
      this.status = 'closing';
      await tick(380);
      runInAction(() => {
        this.status = 'closed';
      });
      resolve();
      this.controller.dismiss(this);
    })
  }
}