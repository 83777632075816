import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Report } from '../../models/makeReport.model';
import { useProps } from '../../utils/mobx.utils';
import SearchListEntry from '../SearchListEntry/SearchListEntry';
import "./SearchList.scss";

type SearchListProps = {
  searchedReports: Report[],
}

const SearchList: React.FC<SearchListProps> = props => {

  const p = useProps(props);

  return <Observer children={() => (
    <>
      {p.searchedReports.map(report => <SearchListEntry key={report.id} report={report} />)}
    </>
  )} />
}

export default SearchList;