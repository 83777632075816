import React from 'react';
import { ReportAccessibleIconType } from '../ReportDownloader/ReportDownloader';

type IconDownloadProps = {
  type?: ReportAccessibleIconType,
}

const IconDownload: React.FC<IconDownloadProps> = props => {
  switch (props.type) {
    case ReportAccessibleIconType.disallow:
      return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M4 4H17V8H18V4V3H17H4H3V4V19V20H4H12V19H4V4ZM6 6H11V7H6V6ZM11 8H6V9H11V8ZM9 10H6V11H9V10Z" fill="currentColor" />
        <path d="M17.5 11V17M17.5 17L14.5 14M17.5 17L20.5 14" stroke="currentColor" />
        <path d="M14 20.5H21" stroke="currentColor" />
        <circle cx="17.5" cy="14.5" r="5" stroke="currentColor" />
        <line x1="13.4798" y1="17.8131" x2="20.8131" y2="10.4798" stroke="currentColor" />
      </svg>
    // return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <path fillRule="evenodd" clipRule="evenodd" d="M4 4H17V8H18V4V3H17H4H3V4V19V20H4H12V19H4V4ZM6 6H11V7H6V6ZM11 8H6V9H11V8ZM9 10H6V11H9V10Z" fill="currentColor" />
    //   <path d="M17.5 11V17M17.5 17L14.5 14M17.5 17L20.5 14" stroke="currentColor" />
    //   <path d="M14 20.5H21" stroke="currentColor" />
    //   <line x1="5.35355" y1="5.64645" x2="16.3536" y2="16.6464" stroke="currentColor" />
    //   <line x1="5.63003" y1="16.6637" x2="15.63" y2="5.66366" stroke="currentColor" />
    // </svg>
    case ReportAccessibleIconType.allow:
    default:
      return <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M4.5 4H17.5V8H18.5V4V3H17.5H4.5H3.5V4V19V20H4.5H12.5V19H4.5V4ZM6.5 6H11.5V7H6.5V6ZM11.5 8H6.5V9H11.5V8ZM9.5 10H6.5V11H9.5V10Z" fill="currentColor" />
        <path d="M18 11V17M18 17L15 14M18 17L21 14" stroke="currentColor" />
        <path d="M14.5 20.5H21.5" stroke="currentColor" />
      </svg>
  }
}

export default IconDownload;