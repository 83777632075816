import { Observer } from 'mobx-react-lite';
import React from 'react';
import joinClassName from '../../utils/className.utils';
import ReportFilterForm from '../ReportFilterForm/ReportFilterForm';
import './ReportIndexSidebar.scss';

type ReportIndexSidebarProps = {
  className?: string,
}

const ReportIndexSidebar: React.FC<ReportIndexSidebarProps> = props => {
  
  return <Observer children={() => (
    <div className={joinClassName('ReportIndexSidebar', props.className)}>
      <ReportFilterForm />
    </div>
  )} />
}

export default ReportIndexSidebar;