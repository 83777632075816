import { action, observable, runInAction } from 'mobx';
import React from 'react';
import { ColorCodedState } from '../../@types/ui.types';
import BaseSpacer from '../../components/BaseSpacer/BaseSpacer';
import ErrorRenderer from '../../components/ErrorRenderer/ErrorRenderer';
import Dialog from '../../constructors/dialog.constructor';
import { renderRenderable } from '../../utils/components.utils';
import { DialogConfig, IDialog } from './ui.controller.types';

export const makeDialogController = () => {

  const c = observable({
    dialogs: [] as IDialog[],
    present: action((d: DialogConfig) => {
      return new Promise((resolve: (d: Promise<any>) => void, reject) => {
        const dialog = new Dialog(d, c);
        runInAction(() => c.dialogs.push(dialog));
        resolve(dialog.promise);
      })
    }),
    positive: action((d: DialogConfig) => {
      return c.present({
        colorCodedState: ColorCodedState.success,
        defaultActions: d.actions ? undefined : ['positive'],
        ...d,
      })
    }),
    success: action((d: DialogConfig) => {
      return c.present({
        colorCodedState: ColorCodedState.success,
        defaultActions: d.actions ? undefined : ['positive'],
        ...d,
      })
    }),
    attention: action((d: DialogConfig) => {
      return c.present({
        colorCodedState: ColorCodedState.attention,
        defaultActions: d.actions ? undefined : ['positive'],
        ...d,
      })
    }),
    alert: action((d: DialogConfig) => {
      return c.present({
        colorCodedState: ColorCodedState.alert,
        defaultActions: d.actions ? undefined : ['positive'],
        ...d,
      })
    }),
    error: action((d: DialogConfig & {
      error?: object | string | unknown
    }) => {
      return c.present({
        colorCodedState: ColorCodedState.error,
        defaultActions: d.actions ? undefined : ['positive'],
        ...d,
        ...d.error ? {
          body: <>
            {d.body && <>
              <BaseSpacer size=".5em" />
              {renderRenderable(d.body)}
            </>}
            <ErrorRenderer e={d.error} />
          </>,
        } : null
      })
    }),
    dismiss: action((d: IDialog | string) => {
      const index = c.dialogs.findIndex(dialog => typeof d === 'string' ? dialog.config.name === d : dialog === d);
      const dialog = c.dialogs[index];
      if (!dialog) return;
      if (dialog.status === 'closed') {
        c.dialogs.splice(index, 1);
      } else {
        dialog.close();
      }
    }),
    get hasDialogs(): boolean {
      return c.dialogs.filter(o => o.status === 'opened').length > 0;
    },
    get lastDialog(): IDialog {
      return c.dialogs[c.dialogs.length - 1];
    },
    reset: action(() => {
      c.dialogs.forEach(d => d.close());
    })
  })

  return c;

}

export type DialogController = ReturnType<typeof makeDialogController>;