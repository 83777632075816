import { useControllers } from "./useRootController.hook";

export const useAppDisplayName = () => {
  const { COMMON } = useControllers();
  return COMMON.app.displayName;
}

export function useAppVersion() {
  const { COMMON } = useControllers();
  return COMMON.app.version;
}