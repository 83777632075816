import React from 'react';

type IconSearchProps = {
}

const IconSearch: React.FC<IconSearchProps> = props => {
  return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M14.6924 9.19238C14.6924 12.2299 12.2299 14.6924 9.19238 14.6924C6.15482 14.6924 3.69238 12.2299 3.69238 9.19238C3.69238 6.15482 6.15482 3.69238 9.19238 3.69238C12.2299 3.69238 14.6924 6.15482 14.6924 9.19238ZM14.0765 14.7835C13.707 14.4139 13.119 14.399 12.6781 14.6797C11.6708 15.3209 10.4749 15.6924 9.19238 15.6924C5.60253 15.6924 2.69238 12.7822 2.69238 9.19238C2.69238 5.60253 5.60253 2.69238 9.19238 2.69238C12.7822 2.69238 15.6924 5.60253 15.6924 9.19238C15.6924 10.4749 15.3209 11.6707 14.6798 12.678C14.3991 13.1188 14.4141 13.7068 14.7836 14.0764L21.0815 20.3743C21.2768 20.5696 21.2768 20.8862 21.0815 21.0814C20.8863 21.2767 20.5697 21.2767 20.3744 21.0814L14.0765 14.7835Z" fill="currentColor"/>
  </svg>
  
}

export default IconSearch;