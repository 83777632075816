import { when } from "mobx";

export const makeConstantPromise = <ResolveType = any, DataType = any>(
  alwaysReturn: ResolveType, 
  beforeResolve?: Function
) => (d: DataType) => {
  beforeResolve && beforeResolve();
  return Promise.resolve<ResolveType>(alwaysReturn);
}

export const resolveWhen = <
  ResolveType = any
>(
  predicate: () => boolean,
  resolver?: () => ResolveType
) => () => new Promise<ResolveType>(resolve => when(
  predicate,
  () => resolve(resolver?.() as ResolveType),
))