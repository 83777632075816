import { KnownBrowserName } from "../utils/browsers.utils";

export type Renderable<P extends object = object> = string | boolean | number | null | undefined | React.ReactElement<P> | React.FC<P> | ((...args: any[]) => React.ReactElement);
export type RenderableLazy<P extends object = object> = React.LazyExoticComponent<React.FC<P>>;

export enum ColorCodedState {
  'neutral' = 'neutral',
  'success' = 'success',
  'positive' = 'positive',
  'attention' = 'attention',
  'warning' = 'warning',
  'alert' = 'alert',
  'error' = 'error',
  'disabled' = 'disabled',
}

export type PointerType = 'mouse' | 'touch' | 'stylus';
export type SimpleOrientationType = 'landscape' | 'portrait';

export type UIControllerDeviceInfo = {
  platform: 'ios' | 'android' | 'web' | string,
  browser: KnownBrowserName[],
  osVersion: string,
  model: string,
  manufacturer: string,
}