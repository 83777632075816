import './LoadingIndicator.scss';

interface P {
  delay?: number | string
}

const LoadingIndicator = (props: P) => {
  return <div className="LoadingIndicator" style={props.delay ? { animationDelay: props.delay + '' } : undefined}>
    <span /><span /><span />
  </div>
}

export default LoadingIndicator;