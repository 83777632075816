import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Report } from '../../models/makeReport.model';
import BaseSpacer from '../BaseSpacer/BaseSpacer';
import './SingleReportFooter.scss';

type SingleReportFooterProps = {
  report: Report
}

const SingleReportFooter: React.FC<SingleReportFooterProps> = props => {
  return <Observer children={() => (
   <div className="SingleReportFooter">
    {props.children}
    <em><strong>This report was published by Carraighill in {props.report.$.created_at}</strong></em>
    <BaseSpacer size=".35em" />
    <em>Use of this site constitutes acceptance of our Terms and Conditions of Use.</em>
   </div>
    
  )} />
}

export default SingleReportFooter;