import { action } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { useReportIndexContext } from '../../controllers/reportIndex.controller';
import { useRootContext } from '../../controllers/root/root.controller';
import { Stock } from '../../models/makeStock.model';
import { useProps, useStore } from '../../utils/mobx.utils';
import BaseCheckbox from '../BaseCheckbox/BaseCheckbox';
import './StocksCheckboxList.scss';

interface StocksCheckboxListProps {
  stocks?: Stock[],
  searchQuery?: string,
}

const StocksCheckboxList: React.FC<StocksCheckboxListProps> = props => {

  const { LOCALDB } = useRootContext();

  const p = useProps(props);
  const reportIndex = useReportIndexContext();

  const s = useStore(() => ({
    get stocks() {
      return p.stocks ?? LOCALDB.data.banks;
    },
    matchesSearch: (bank: Stock) => {
      return bank.$.name?.toLowerCase().includes(p.searchQuery?.toLowerCase() ?? "") ?? false
    },
  }));
  return <Observer children={() => (
    <ul className="CheckboxList StocksCheckboxList">
      {s.stocks.map(bank =>
        (!p.searchQuery || s.matchesSearch(bank)) ?
          <li className="AnimateEntry" key={bank.$.id}>
            <BaseCheckbox
              getter={() => reportIndex.stockFilterIds.includes(bank.$.id!)}
              setter={action(() => reportIndex.updateStockFilter(bank))}
              Label={<>{bank.$.name}</>}
            />
          </li> :
          ""
      )}
    </ul>
  )} />
}

export default StocksCheckboxList;