import { Observer } from 'mobx-react-lite';
import React from 'react';
import './PageContent.scss';

type PageContentProps = {}

const PageContent: React.FC<PageContentProps> = props => {
  return <Observer children={() => (
    <div className="PageContent">
      <div className="PageContentInner">
        { props.children }
      </div>
    </div>
  )} />
}

export default PageContent;