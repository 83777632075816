import { Observer } from 'mobx-react-lite';
import React, { SyntheticEvent } from 'react';
import { isNull } from 'util';
import { AnyObject, StringKeyOf } from '../../@types/base.types';
import joinClassName from '../../utils/className.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import { getRandomNumericString } from '../../utils/random.utils';
import './BaseToggle.scss';

export type BaseToggleProps<T extends AnyObject> = {
  className?: string,
  name?: string,
  label?: string | React.ReactElement;
  form: T,
  field: StringKeyOf<T>,
  appearance?: 'toggle' | 'checkbox' | 'radio',
  onClick?: (e?: SyntheticEvent) => unknown,
  onChange?: (newValue?: any) => unknown,
  disabled?: any,
  trueValue?: any,
  falseValue?: any,
  trueIconLabelValue?: string,
  falseIconLabelValue?: string,
  nullable?: any,
  fullWidth?: boolean,
}

const BaseToggle = <T extends AnyObject>(props: React.PropsWithChildren<BaseToggleProps<T>>) => {
  const p = useProps(props);
  
  const s = useStore(() => ({
    identifier: getRandomNumericString(6),
    get name() {
      return props.name;
    },
    get id() {
      return `${p.name || p.field.toString()}-${s.identifier}`
    },
    get trueValue() {
      return p.trueValue === undefined ? true : p.trueValue;
    },
    get falseValue() {
      return p.falseValue === undefined ? false : p.falseValue;
    },
    get value() {
      const realValue = p.form[p.field];
      if (realValue === null) return '';
      return realValue;
    },
    get nullable() {
      return p.nullable ?? true;
    },
    set value(v: any) {
      if (isNull(v) && !s.nullable) return;
      p.form[p.field] = v;
    },
    get toggleIconLabel() {
      return s.isTrue ? (!p.trueIconLabelValue ? '' : p.trueIconLabelValue)
        : (!p.falseIconLabelValue ? '' : p.falseIconLabelValue);
    },
    toggleValue: () => {
      if (p.disabled) return;
      s.value = s.isTrue ? s.falseValue : s.trueValue;
      p.onChange && p.onChange(s.value);
    },
    get isTrue() {
      return s.value === s.trueValue;
    },
    isInverted: false,
    
    handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => {
      s.toggleValue();
    },
    get commonAttr() {
      return {
        id : s.id,
        name: p.name || p.field.toString(),
        onChange: s.handleInputChange,
        disabled : p.disabled
      }
    },

  }))

  return <Observer children={() => (
    <div className={joinClassName('BaseToggle', p.className,s.isTrue ? 'true' : 'false',  p.disabled && 'disabled',s.isInverted && 'inverted',p.fullWidth && 'fullWidth')}>
      {
        p.appearance === 'radio' ? <input type='radio' value={s.value} {...s.commonAttr} /> : (
          <input type='checkbox' checked={s.value} {...s.commonAttr} />
        )
      }
      <label className="BaseToggleInner" htmlFor={s.id}>
        <span className="BaseToggleBox">
          <span className="BaseToggleBoxMarker">{
            p.appearance === 'toggle' ? s.toggleIconLabel : (
              <svg className="BaseToggleBoxCheck" viewBox="0 0 14 10" fill="none">
                <path d="M1.125 3.96875L5.28125 8.125L12.3906 1.01562" stroke="currentColor" strokeWidth="2" />
              </svg>
            )
          }</span>
        </span>
        {(p.label || p.children) && (
          <span className="BaseToggleLabel">
            {p.label}
            {p.children}
          </span>
        )}
      </label>
      
    </div>
  )} />
}

export default BaseToggle;