import { useContext } from "react";
import { RootContext } from "../controllers/root/root.controller";

export function useRootController() {
  return useContext(RootContext);
}
export function useControllers() {
  return useRootController();
}
export function useUIController() {
  return useRootController().UI;
}
export function useDialogController() {
  return useRootController().UI.DIALOG;
}
export function useNativeController() {
  return useRootController().UI.NATIVE;
}
export function usePortalController() {
  return useRootController().UI.PORTAL;
}
