import { Observer } from 'mobx-react-lite';
import React from 'react';
import { AnyObject } from '../../@types/base.types';
import { isObject, isString } from '../../utils/typeChecks.utils';

interface ErrorListProps {
  errors: object
}

const ErrorListItem: React.FC<{e: AnyObject}> = props => {
  return <Observer children={() => (
    <span className="ErrorListItem">
      {isNaN(+props.e[0]) && <><strong>{props.e[0]}</strong>: </>}
      {isString(props.e[1]) ? <span>{props.e[1]}</span> : isObject(props.e[1]) ? <ErrorList errors={props.e[1]} /> : props.e[1] + ''}
    </span>
  )} />
}

const ErrorList: React.FC<ErrorListProps> = props => {
  return <Observer children={() => {
    const array = Object.entries(props.errors);
    return (
      array.length === 1 ? <ErrorListItem e={array[0]} /> :
        <ul className="ErrorList">
          { array.map((d, i) => <li key={i + d[0]}>
            <ErrorListItem e={d} />
          </li>)}
        </ul>
    )
  }} />
}

export default ErrorList;