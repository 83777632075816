import { action, when } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Route } from 'react-router-dom';
import AppFooter from '../../components/AppFooter/AppFooter';
import AppNavBar from '../../components/AppNavBar/AppNavBar';
import BaseButton from '../../components/BaseButton/BaseButton';
import GlobalNoScroll from '../../components/GlobalNoScroll/GlobalNoScroll';
import LoadingBlocker from '../../components/LoadingBlocker/LoadingBlocker';
import PageContent from '../../components/PageContent/PageContent';
import PageContentHeader from '../../components/PageContentHeader/PageContentHeader';
import ReportEntryList from '../../components/ReportEntryList/ReportEntryList';
import ReportIndexSidebar from '../../components/ReportIndexSidebar/ReportIndexSidebar';
import WindowTitle from '../../components/WindowTitle/WindowTitle';
import { makeReportIndexController, ReportIndexContext } from '../../controllers/reportIndex.controller';
import { useOnMount } from '../../hooks/lifecycle.hooks';
import { useControllers } from '../../hooks/useRootController.hook';
import { replaceContents } from '../../utils/array.utils';
import joinClassName from '../../utils/className.utils';
import { useStore } from '../../utils/mobx.utils';
import PageReportSingle from '../PageReportSingle/PageReportSingle';
import './PageReportIndex.scss';

type PageReportIndexProps = {
  autoOpen?: boolean,
}

const PageReportIndex: React.FC<PageReportIndexProps> = props => {

  const { UI, REPORTS, NAVIGATOR } = useControllers();

  const s = useStore(() => ({
    get allReports() {
      return REPORTS.reports
    },
    /** place this in useStore, so it only gets created once per component instance */
    reportIndexController: makeReportIndexController(),
    get filteredReports() {
      return s.reportIndexController.filteredReports;
    },
    open: props.autoOpen ?? false,
    toggleOpenState: action(() => {
      s.open = !s.open;
    }),
    dismiss: () => {
      NAVIGATOR.redirectTo('/reports');
    },
  }));

  useOnMount(() => {
    return when(
      () => !!s.allReports?.length,
      () => {
        replaceContents(s.reportIndexController.reports, s.allReports);
        // s.reportIndexController.showLatestReports()
      }
    )
  })

  return <Observer children={() => (
    <ReportIndexContext.Provider value={s.reportIndexController}>
      <div className="PageReportIndex">
        <AppNavBar />
        <WindowTitle title="View Reports" />
        {s.allReports.length === 0 && <LoadingBlocker />}
        <div className='PageReportIndexContent PageBackdropLayer'>
          <ReportIndexSidebar className={joinClassName(s.open && "OpenFilterSideBar")} />
          {s.open && <GlobalNoScroll />}
          {s.open && UI.uptoTabletLg && <div className="PageReportBackdrop" onClick={s.toggleOpenState} />}
          <PageContent>
            <PageContentHeader
              Title={s.reportIndexController.isLatestReports ? "Latest Reports" : "Filtered Reports"}
              AfterTitle={s.reportIndexController.isLatestReports ?
                  "" :
                  `${s.filteredReports.length} ${(s.filteredReports.length === 1 ? 'report matches' : 'reports match')} with your filters.`
              }
            />
            <ReportEntryList reports={s.filteredReports} />
            <footer>
              <AppFooter />
            </footer>
            <BaseButton className={joinClassName('filterButton', s.open && 'filterButtonOpen')} title="Filter Report" onClick={s.toggleOpenState}>
              <svg width="5" height="9" viewBox="0 0 5 9" fill="none"><path d="M4 4L0 0V8L4 4Z" fill="currentColor" /></svg>
              Filter
            </BaseButton>
          </PageContent>
        </div>
        <Route path="/reports/:reportId" children={<PageReportSingle />} />
      </div>
    </ReportIndexContext.Provider>
  )} />
}

export default PageReportIndex;