import { Observer } from "mobx-react-lite";
import React from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { ColorCodedState } from "../../@types/ui.types";
// import { ContextColor } from "../../constants/color.enum";
import { IDialog } from "../../controllers/ui/ui.controller.types";
// import { useContextColorStyle } from "../../hooks/useContextColor.hook";
import { useObservableRef } from "../../hooks/useObservableRef.hook";
import { useUIController } from "../../hooks/useRootController.hook";
import joinClassName from "../../utils/className.utils";
import { useProps, useStore } from "../../utils/mobx.utils";
import './Dialog.scss';
import OldDialog from "./DialogOld";

interface P {
  dialog: IDialog,
}

const Dialog: React.FC<P> = props => {

  const p = useProps(props);

  const { PORTAL } = useUIController();

  const s = useStore(() => ({
    dialog: p.dialog,
    config: p.dialog.config,
    get heading() {
      const { heading: Heading } = s.dialog.config;
      if (typeof Heading === 'function') return <Heading />
      else return Heading;
    },
    get body() {
      const { body: Body } = s.dialog.config;
      if (typeof Body === 'function') return <Body />
      else return Body;
    },
    get actions() {
      return s.dialog.actions;
    },
    get someWaiting() {
      return s.actions.some(a => a.waiting);
    },
    get className() {
      switch (p.dialog.config.colorCodedState) {
        case ColorCodedState.success:
        case ColorCodedState.positive:
          return "SuccessRenderer"
        case ColorCodedState.alert:
        case ColorCodedState.attention:
        case ColorCodedState.warning:
          return "AttentionRenderer";
        case ColorCodedState.error:
          return "ErrorRenderer";
        default:
          return "";
      }
    },
  }));

  // const style = useContextColorStyle(ContextColor.Primary, p.dialog.config.color);

  const ref = useObservableRef();

  useHotkeys('enter', () => {
    if (!ref.current) return;
    const positiveButton = ref.current.querySelector<HTMLButtonElement>(`[data-name="positive"]`) ||
      ref.current.querySelector<HTMLButtonElement>(`[title="OK"]`) ||
      ref.current.querySelector<HTMLButtonElement>(`[title="Confirm"]`) ||
      ref.current.querySelector<HTMLButtonElement>(`.DialogFooter .BaseButton:only-child`)
    if (positiveButton) positiveButton.click();
  })

  useHotkeys('escape', () => {
    if (!ref.current) return;
    const negativeButton = ref.current.querySelector<HTMLButtonElement>(`[data-name="negative"]`) ||
      ref.current.querySelector<HTMLButtonElement>(`[title="Cancel"]`) ||
      ref.current.querySelector<HTMLButtonElement>(`[title="No"]`) ||
      ref.current.querySelector<HTMLButtonElement>(`.DialogFooter .BaseButton:only-child`)
    if (negativeButton) negativeButton.click();
  })

  return <Observer children={() => (
    PORTAL.render(
      <section
        className={joinClassName(
          "DialogLayer"
        )}
      >
        <OldDialog
          className={joinClassName(s.className)}
          dialogHeading={s.heading as string}
          dialogMessage={s.body as string}
          onClickHandler={s.actions?.[s.actions.length - 1]?.action!}
        />
      </section>
      // <section
      //   className={joinClassName(
      //     "DialogLayer",
      //     s.dialog.status,
      //     s.dialog.config.colorCodedState && `state-${s.dialog.config.colorCodedState}`
      //   )}
      //   data-dialog-name={s.dialog.config.name}
      //   style={style}
      //   ref={ref}
      // >
      //   <span className="DialogBackdrop" />
      //   <div className="Dialog" style={{maxWidth: s.config.width}}>
      //     {s.dialog.config.customComponent ? renderRenderable(s.dialog.config.customComponent) : <>
      //       {s.heading && <header className="DialogHeader">
      //         <h1 className="DialogHeading">{s.heading}</h1>
      //       </header>}
      //       {s.body && <div className="DialogBody">{s.body}</div>}
      //       <footer className="DialogFooter">
      //         {
      //           s.actions.map(a => (
      //             <BaseButton
      //               onClick={a.action}
      //               key={a.id}
      //               appearance={a.buttonAppearance}
      //               loading={a.waiting}
      //               disabled={a.disabled || s.someWaiting}
      //               className={a.buttonClass}
      //               color={a.color}
      //               name={a.name || a.label}
      //               title={a.label || a.name}
      //               colorCodedState={a.colorCodedState}
      //               label={a.label}
      //             />
      //           ))
      //         }
      //       </footer>
      //     </>}
      //   </div>
      // </section>
    )
  )} />
}


export default Dialog;