import { action, observable } from "mobx";
import { AppInfo } from "./common/appInfo";
import { RootController } from "./root/root.controller";

/**
 * "CommonController" includes shared basic information about the app, the location, the current time, online status and more.
 */
export type CommonController = ReturnType<typeof makeCommonController>;

export const makeCommonController = () => {

  const c = observable({
    ROOT: null as RootController | null,
    online: false,
    app: AppInfo,
    init: action((root: RootController) => {
      c.ROOT = root;
      _initOnlineStatusWatcher();
    }),
  })

  const _initOnlineStatusWatcher = () => {
    window.addEventListener('online', action(() => {
      console.log('%cDEVICE ONLINE', 'color: #77F117');
      c.online = true;
    }))
    window.addEventListener('offline', action(() => {
      console.log('%cDEVICE OFFLINE', 'color: #F11717');
      c.online = false;
    }))
  };

  return c;

}