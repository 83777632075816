import { flow } from "mobx";
import { Observer } from "mobx-react-lite";
import { useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { useRootContext } from "../../controllers/root/root.controller";
import joinClassName from "../../utils/className.utils";
import { reportError } from "../../utils/errors.utils";
import { useStore } from "../../utils/mobx.utils";
import { validateEmail } from "../../validators/email.validator";
import { makePasswordValidator } from "../../validators/password.validator";
import BaseButton from "../BaseButton/BaseButton";
import BaseInput from "../BaseInput/BaseInput";
import BaseSpacer from "../BaseSpacer/BaseSpacer";
import CarraighillLogo from "../CarraighillLogo/CarraighillLogo";
import Dialog from "../Dialog/DialogOld";
import "./ResetPassword.scss";

interface ResetPasswordProps {}

const ResetPassword: React.FC<ResetPasswordProps> = (props) => {
  const { NAVIGATOR, AUTH } = useRootContext();
  const [resetPasswordState, setResetPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { token }: any = useParams();
  const s = useStore(() => ({
    form: {
      token: token,
      email: "",
      password: "",
      password_confirmation: "",
    },
    error: {
      show: false as boolean,
      msg: "" as string,
    },
    passwordValidator: makePasswordValidator({ minLength: 8 }),
    get passwordValidatorResult() {
      return s.passwordValidator(s.form.password);
    },
    get emailIsValid() {
      return validateEmail(s.form.email) === true;
    },
    get passwordIsValid() {
      return s.passwordValidatorResult === true;
    },
    get newPasswordMatches() {
      return s.form.password === s.form.password_confirmation;
    },
    get canSubmit() {
      return Boolean(s.form.email && s.form.password && s.newPasswordMatches);
    },

    submit: async () =>
      await flow(function* () {
        try {
          const response = yield AUTH.resetPassword(s.form);
          if (response.status === 200) {
            setResetPassword("passwordUpdated");
            setTimeout(() => {
              NAVIGATOR.redirectTo("/login");
            }, 2000);
          }
        } catch (e: any) {
          setResetPassword("passwordResetFailed");
          reportError(e);
          if (e.response?.data?.errors ?? false) {
            const errorMessageKey = Object.keys(e.response.data.errors)[0];
            setErrorMessage(e.response.data.errors[errorMessageKey]);
          } else {
            setErrorMessage(
              "Unexpected error occurred while resetting password!"
            );
          }
        }
      })(),
    dismiss() {
      setResetPassword("");
    },
    resetErrorState() {
      s.error.show = false;
      s.error.msg = "";
    },
  }));

  return (
    <Observer
      children={() => (
        <div className="ForgotPasswordForm ResetPasswordForm">
          <CarraighillLogo />
          <form>
            <h3>Reset Password</h3>
            <BaseSpacer />
            <BaseInput
              form={s.form}
              field="email"
              type="email"
              Label={<>E-mail address</>}
              placeholder="Enter your email..."
              autoComplete="email"
              onEnter={s.submit}
            />
            <BaseSpacer />
            <BaseInput
              form={s.form}
              field="password"
              type="password"
              Label="New Password"
              placeholder="Enter new password"
              autoComplete="password"
              onEnter={s.submit}
            />
            <BaseSpacer />
            <BaseInput
              form={s.form}
              field="password_confirmation"
              type="password"
              Label="Confirm New Password"
              placeholder="Confirm  password"
              autoComplete="password_confirmation"
              onEnter={s.submit}
            />
            <BaseSpacer />
            <div className="ForgotPasswordFormSubmission">
              <BaseButton
                title="Back to Log in"
                label="Back to Log in"
                onClick={() => NAVIGATOR.redirectTo("/login")}
              />
              <BaseButton
                disabled={!s.canSubmit}
                title="Confirm Change Password"
                onClick={s.submit}
              >
                Reset Password
              </BaseButton>
              {resetPasswordState === "passwordResetFailed" && (
                <Dialog
                  className={joinClassName("ErrorRenderer")}
                  dialogHeading="Failed to Reset Password"
                  dialogMessage={errorMessage}
                  onClickHandler={s.dismiss}
                />
              )}
              {resetPasswordState === "passwordUpdated" && (
                <Dialog
                  dialogHeading="Password Updated Successfully"
                  onClickHandler={s.dismiss}
                />
              )}
            </div>
            {AUTH.isAuthenticated && <Redirect to="/reports" />}
          </form>
          {s.error.show && (
            <Dialog
              className={joinClassName("ErrorRenderer")}
              dialogHeading="Failed to send password reset link."
              dialogMessage={s.error.msg}
              onClickHandler={s.resetErrorState}
            />
          )}
          {props.children}
        </div>
      )}
    />
  );
};

export default ResetPassword;
