import { action } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Report } from '../../models/makeReport.model';
import { useStore } from '../../utils/mobx.utils';
import ReportEntry from '../ReportEntry/ReportEntry';
import './ReportEntryList.scss';

type ReportEntryListProps = {
  reports: Report[],
}

const ReportEntryList: React.FC<ReportEntryListProps> = props => {
  const s = useStore(() => ({
    openReportId: 0,
    handleReportChange: action((newReportId : number) => {
      s.openReportId = newReportId;
    })
  })); 
  return <Observer children={() => (
    <div className="ReportEntryList AnimateEntry">
      { props.reports.map(report => <ReportEntry currentOpenedReport={s.openReportId} onChange={s.handleReportChange} report={report} key={report.$.id} />) }
    </div>
  )} />
}

export default ReportEntryList;