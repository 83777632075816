import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import packageJson from '../package.json';
import './App.scss';
import { DialogStack } from './components/Dialog/DialogStack';
import HoldReportInfo from './components/HoldReportInfo/HoldReportInfo';
import InternalSiteMarker from './components/InternalSiteMarker/InternalSiteMarker';
import LoadingBlocker from './components/LoadingBlocker/LoadingBlocker';
import NavigatorRedirectWatcher from './components/NavigatorRedirectWatcher/NavigatorRedirectWatcher';
import TitleManager from './components/TitleManager/TitleManager';
import UpdatePassword from './components/UpdatePassword/UpdatePassword';
import { useRootContext } from './controllers/root/root.controller';
import { useOnMount } from './hooks/lifecycle.hooks';
import { useObservableRef } from './hooks/useObservableRef.hook';
import PageAccountManagement from './pages/PageAccountManagement/PageAccountManagement';
import PageForgotPassword from './pages/PageForgotPassword/PageForgotPassword';
import PageLogin from './pages/PageLogin/PageLogin';
import PageLogout from './pages/PageLogout/PageLogout';
import PageReportIndex from './pages/PageReportIndex/PageReportIndex';
import PageResetPassword from './pages/PageResetPassword/PageResetPassword';
import { warnOutdatedBrowser } from './utils/browsers.utils';


function App() {

  const ROOT = useRootContext();
  const { UI, AUTH } = ROOT;
  const appContainerRef = useObservableRef<HTMLDivElement>();
  const portalRef = useObservableRef<HTMLDivElement>();

  useOnMount(() => {
    UI.PORTAL.registerRef(portalRef);
    document.documentElement.setAttribute('data-version', packageJson.version);
    UI.registerAppContainerRef(appContainerRef);

    if (UI.browserIsSeverelyOutdated) {
      warnOutdatedBrowser(UI);
    }
  });

  return <Observer children={() => {
    return ROOT.ready ? <div
      className='App'
      ref={appContainerRef}
      data-authenticated={AUTH.isAuthenticated}
    >
      <div className="AppPortal" ref={portalRef} />
      <Switch>
        <Route path="/logout" children={<PageLogout />} />
        <Route path="/login" children={<PageLogin />} />
        <Route path="/forgot-password" children={<PageForgotPassword />} />
        {AUTH.isAuthenticated && <Route path="/account" children={<PageAccountManagement />} />}
        {AUTH.isAuthenticated && <Route path="/update-password" children={<UpdatePassword />} />}
        <Route path="/password/reset/:token" children={<PageResetPassword />} />
        {AUTH.isAuthenticated && <Route path="/reports" children={<PageReportIndex />} />}
        <Route path="/reports/:id" children={<HoldReportInfo />}/>
        <Route path='/auth/report/:id' children={<HoldReportInfo />}/>
        <Redirect to={AUTH.isAuthenticated ? '/reports' : '/login'} /> 
      </Switch>
      
      <TitleManager />
      {/* { !AUTH.isAuthenticated && <Redirect to="/login" /> } */}
      <DialogStack />
      <NavigatorRedirectWatcher />
      <InternalSiteMarker />
    </div> : <LoadingBlocker />
  }} />

}

export default App;
