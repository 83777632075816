import { action } from "mobx";
import { Observer } from "mobx-react-lite";
import { useOnMount } from "../../hooks/lifecycle.hooks";
import { Report } from "../../models/makeReport.model";
import joinClassName from "../../utils/className.utils";
import { useProps, useStore } from "../../utils/mobx.utils";
import CarraighillLogo from "../CarraighillLogo/CarraighillLogo";
import './ReportImage.scss';

interface ReportImageProps {
  report: Report,
  className?: string,
}

const ReportImage: React.FC<ReportImageProps> = props => {

  const p = useProps(props);

  const s = useStore(() => ({
    get imgLink() {
      return p.report.$.cover_image_url ?? "";
    },
    get imgStyle() {
      return s.imgLink ?
        {
          backgroundImage: `url(${s.imgLink})`
        } :
        {}
    },
    _orientation: "" as string,
    get orientation() {
      return s._orientation;
    },
    updateOrientation: () => {
      if (!s.imgLink) return "";

      const img = new Image();
      img.src = s.imgLink;
      img.onload = action(() => {
        if (img.naturalWidth > img.naturalHeight) {
          s._orientation = 'landscape';
        } else if (img.naturalWidth < img.naturalHeight) {
          s._orientation = 'portrait';
        } else {
          s._orientation = 'even';
        }
      })
    },
  }));

  useOnMount(() => {
    s.updateOrientation();
  });

  return <Observer children={() => (
    <div className="ReportImageWrapper">
      <div className={joinClassName("ReportImage", s.orientation)} style={s.imgStyle} title={props.report.$.title}>
        <div className={joinClassName("FallbackImage", !s.imgLink ? "active" : "")}><CarraighillLogo /></div>
      </div>
    </div>
  )} />
}

export default ReportImage;