import { Observer } from 'mobx-react-lite';
import React, { ReactNode } from 'react';
import { useProps } from '../../utils/mobx.utils';
import './PageContentHeader.scss';

type PageContentHeaderProps = {
  Title: ReactNode,
  AfterTitle?: ReactNode,
  Controls?: ReactNode,
}

const PageContentHeader: React.FC<PageContentHeaderProps> = props => {

  const p = useProps(props);

  return <Observer children={() => (
    <div className="PageContentHeader">
      <div className="PageContentHeaderContent">
        <h1 className="PageContentHeaderTitle">{p.Title}</h1>
        {/* <BaseSpacer /> */}
        <p className="PageContentHeaderAfterTitle">{p.AfterTitle}</p>
      </div>
      { p.Controls && <div className="PageContentHeaderControls">
        {p.Controls}
      </div> }
    </div>
  )} />
}

export default PageContentHeader;