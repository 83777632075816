import download from 'downloadjs';
import { action, observable, reaction } from 'mobx';
import { Report } from '../../models/makeReport.model';
import { AXIOS } from '../../utils/axios.utils';
import { reportError } from '../../utils/errors.utils';
import { makeHeaders } from './api.controller';
import { RootController } from './root.controller';

export const makeReportsController = () => {
  const s = observable({
    ROOT: null as RootController | null,
    ready: false as boolean,
    redirectedURL : '',
    get redirectedReportURL(){
      return s.redirectedURL;
    },
    get reports() {
      return s.ROOT?.LOCALDB.data.reports ?? [];
    },
    reset: () => {},
    getReportPDF: async (report: Report) => {
      const { id, title } = report.$;
      const headers = makeHeaders();
      
      // eslint-disable-next-line
      const downloadActivity = AXIOS.post(`api/activity/${id}/entry-download`, [], { headers});

      try {
        const reportUrlResp = await AXIOS.get(`api/download/report/${id}`, { headers })
        const reportUrl = reportUrlResp.data;
        const fileResp = await AXIOS.get(reportUrl, {
          withCredentials: false,
          responseType: "blob",
        });
        const contentType = fileResp.headers["content-type"];
        download(fileResp.data, `${!!title ? title : "Untitled"}.pdf`, contentType);
        return fileResp;
      } catch (e: any) {
        reportError(e);
        return e.response.status;
      }
    },
    getAllReports: async () => {
      await s.ROOT?.API.getMany("/api/reports", "reports");
      s.ready = true;
    },
    init: action((root: RootController) => {
      s.ROOT = root;
      reaction(
        () => s.ROOT?.AUTH.isAuthenticated,
        (isAuthenticated) => isAuthenticated && s.getAllReports(),
        { fireImmediately: true }
      );
    }),
  });
  return s;

}

export type ReportsController = ReturnType<typeof makeReportsController>;
