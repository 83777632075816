import moment from 'moment';
import { Nillable } from '../@types/base.types';

export const YYYYMMDD = 'YYYY-MM-DD';
export const YYYYMMDDHHmmss = 'YYYY-MM-DD HH:mm:ss';

export const createLocalMomentFromUTCTime = (value: string) => moment.utc(value).local();

export function getDateFromUTC(dateValue : string ) : string{ return moment(dateValue).format('DD MMM YY'); };


export type Timeframe = {
  startDate?: Nillable<string>,
  endDate?: Nillable<string>,
}