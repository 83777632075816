import { action, reaction } from "mobx";

export const resolveAfter = (msMin?: number, msMax?: number) => {
  const duration = msMin && msMax ? Math.random() * (msMax - msMin) + msMin : msMin;
  return new Promise(resolve => setTimeout(resolve, duration));
}
export default resolveAfter;

export const runAfter = async (fn: Function, wait?: number) => {
  let alive = true;
  await resolveAfter(wait);
  if (alive) fn();
  return () => {
    alive = false;
  }
}

export const runEvery = (
  fn: Function,
  interval: number,
  options?: {
    fireImmediately?: boolean,
    until?: () => boolean,
    while?: () => boolean,
  }
) => {
  const ref = setInterval(() => {
    if (options?.while && !options.while()) {
      cleanup();
      return;
    }
    fn();
  }, interval);
  if (options?.fireImmediately) fn();
  let disposer: Function;
  if (options?.until) {
    disposer = reaction(options.until, value => value && cleanup());
  }
  const cleanup = () => {
    clearInterval(ref);
    disposer?.();
  }
  return cleanup
}

export const runOnEachTick = async (...actions: Function[]) => {
  for (let a of actions) {
    await resolveAfter();
    action(a)();
  }
}