import { Observer } from 'mobx-react-lite';
import React from 'react';

type BrowserRecommendationListProps = {}

const BrowserRecommendationList: React.FC<BrowserRecommendationListProps> = props => {
  return <Observer children={() => (
    <ul className="BrowserRecommendationList">
      <li><a href="https://www.google.com/chrome/" title="Get Chrome Browser" target="_blank" rel="noreferrer">Google Chrome</a></li>
      <li><a href="https://www.mozilla.org/en-US/firefox/new/" title="Get Firefox Browser" target="_blank" rel="noreferrer">Firefox</a></li>
      <li><a href="https://www.microsoft.com/en-us/edge" title="Get latest Edge Browser" target="_blank" rel="noreferrer">Latest Microsoft Edge</a></li>
      <li>Latest Safari (Apple's default browser)</li>
    </ul>
  )} />
}

export default BrowserRecommendationList;