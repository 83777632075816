import { observable } from "mobx";

export const makeCategorySnapshot = () => ({
  id: null as null | number,
  name: '',
  description: '',
  type: '' ,
  slug: '',
  created_at: '' as string | null,
  updated_at: '' as string | null,
})

export type CategorySnapshot = ReturnType<typeof makeCategorySnapshot>;

export const makeCategory = (snapshot?: Partial<CategorySnapshot>) => {

  const s = observable({
    $: snapshot ?? makeCategorySnapshot(),
    get id() {
      return s.$.id;
    },
  });
  return s;
}

export type Category = ReturnType<typeof makeCategory>;

