import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Link } from 'react-router-dom';
import { useControllers } from '../../hooks/useRootController.hook';
import { Report } from '../../models/makeReport.model';
import { useProps, useStore } from '../../utils/mobx.utils';
import { getDateFromUTC } from '../../utils/time.utils';
import BaseSpacer from '../BaseSpacer/BaseSpacer';
import IconClose from '../IconClose/IconClose';
import ReportDownloader, { DownloaderViewType } from '../ReportDownloader/ReportDownloader';
import ReportImage from '../ReportImage/ReportImage';
import './SingleReportHeader.scss';

type SingleReportHeaderProps = {
  report: Report
}

const SingleReportHeader: React.FC<SingleReportHeaderProps> = props => {

  const { LOCALDB } = useControllers();

  const p = useProps(props);

  const s = useStore(() => ({
    get categories() {
      return LOCALDB.data.categories;
    },
    get firstReportCategoryId() {
      if (p.report.$.categories && p.report.$.categories.length > 0) return p.report.$.categories[0].id;
      return undefined;
    },
    reportCategoryFromID(categoryID: string | number) {
      const categoryObj = this.categories.find(category => category.$.id === +categoryID);
      return categoryObj?.$.name;
    },
  }));

  return <Observer children={() => (
    <div className="SingleReportHeader">
      <div className="SingleReportHeaderContentContainer">
        <ReportImage report={p.report} />
        {/* <div className="SingleReportHeaderImageWrapper">
          <div className="SingleReportHeaderImage" style={s.imgStyle} title={props.report.$.title} />
        </div> */}
        <div className="SingleReportHeaderContent">
          <span className="SingleReportHeaderCategoryName">{s.reportCategoryFromID(s.firstReportCategoryId ?? "")}</span>
          {(s.firstReportCategoryId ?? false) && <BaseSpacer size=".5em"/>}
          <h1 className="SingleReportHeaderTitle">{props.report.$.title}</h1>
          {p.report.$.subtitle! && <BaseSpacer size=".5em"/>}
          <span className="ReportEntryHeaderSubtitle">{p.report.$.subtitle}</span>
          <BaseSpacer />
          <span className="SingleReportHeaderPublishedDate">Published on {getDateFromUTC(props.report.$.created_at!)}</span>
          <BaseSpacer />
          <ReportDownloader report={p.report} downloaderViewType={DownloaderViewType.SINGLE_REPORT_HEADER} />
        </div>
      </div>
      <Link to="/reports" className="CloseReport">
        <IconClose />
      </Link>
    </div>
  )} />
}

export default SingleReportHeader;