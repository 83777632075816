import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Link } from 'react-router-dom';
import BaseSpacer from '../BaseSpacer/BaseSpacer';
import LoginFooterLogoSet from '../FooterLogoSet/FooterLogoSet';
import './AppFooter.scss';

type AppFooterProps = {}

const AppFooter: React.FC<AppFooterProps> = props => {
  return <Observer children={() => (
    <div className="AppFooter">
      <div className="AppFooterInner">
        { props.children }
        <div className="AppFooter__Content">
          <div>
            <p><strong>© 2021 <Link to="/reports" title="Carraighill Capital Ltd.">Carraighill Capital Ltd.</Link></strong></p>
            <p>All rights reserved. This is a secure site. Access is by invitation only and requires a username and password. </p>
            <p>Use of this website is governed by a User Agreement. The content contained on this site is provided to users ''as is'' without any express or implied warranty.</p>
            <BaseSpacer />
            <ul>
              <li><Link to="/" title="Terms of Service">Terms of Service</Link></li>
              <li><Link to="/" title="Contact us">Contact us</Link></li>
              <li><Link to="/" title="Company Brochure">Company Brochure</Link></li>
              <li><Link to="/" title="GDPR Policy">GDPR Policy (PDF)</Link></li>
            </ul>
          </div>
          <div className="AppFooter__Content--Address">
            <p>If you experience any problems while using our services,<br/> please contact us at <a href="mailto:admin@carraighill.com"><strong>admin@carraighill.com.</strong></a></p>
            <BaseSpacer />
            <address>
              <span>Registered Office: </span>
              <span>Eagle House, 5 Marine Road.</span>
              <span>Dún Laoghaire, Co. Dublin</span>
            </address>
            <BaseSpacer />
            <p>Registered in Ireland. No. 529193</p>
          </div>
        </div>
        <div className="AppFooter__LogoBox">
          <p><strong>Supported by our Local Enterprise Office</strong></p>
          <LoginFooterLogoSet />
        </div>
      </div>
    </div>
  )} />
}

export default AppFooter;