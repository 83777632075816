import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { useRootContext } from '../../controllers/root/root.controller';

type NavigatorRedirectWatcherProps = {}

const NavigatorRedirectWatcher: React.FC<NavigatorRedirectWatcherProps> = props => {
  const { NAVIGATOR } = useRootContext();
  return <Observer children={() => (
    NAVIGATOR.shouldRedirectTo ? <Redirect to={NAVIGATOR.shouldRedirectTo} /> : null
  )} />
}

export default NavigatorRedirectWatcher;