import { observable } from "mobx";

export const makeCountrySnapshot = () => ({
  id: null as null | number,
  name: '',
  key: '',
  type: '' ,
  slug: '',
  status: '',
  continent: '' as string | null,
  created_at: '' as string | null,
  updated_at: '' as string | null,
  is_accessible: true as boolean | undefined,
})

export type CountrySnapshot = ReturnType<typeof makeCountrySnapshot>;

export const makeCountry = (snapshot?: Partial<CountrySnapshot>) => {

  const s = observable({
    $: snapshot ?? makeCountrySnapshot(),
    get id() {
      return s.$.id;
    },
  });
  return s;
}

export type Country = ReturnType<typeof makeCountry>;

