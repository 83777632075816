import React from 'react';
import { isStandardSize, Size } from '../../constants/sizes.constants';
import './BaseSpacer.scss';

type BaseSpacerProps = {
  size?: Size | string | number,
  inline?: boolean,
}

const BaseSpacer: React.FC<BaseSpacerProps> = props => {
  const { size = '1em' } = props;
  const isCustomValue = typeof props.size === 'number' || !isStandardSize(size);
  const style = {
    display: props.inline ? 'inline-block' : undefined,
    width: isCustomValue ? size : undefined,
    height: isCustomValue ? size : undefined,
  }
  return <div className="BaseSpacer" data-size={props.size} style={style} />
}

export default BaseSpacer;