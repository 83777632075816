import { observable } from "mobx";

// Stock is actually a bank.
export const makeStockSnapshot = () => ({
  id: null as null | number,
  name: '',
  description: '',
  slug: '',
  created_at: '' as string | null,
  updated_at: '' as string | null,
})

export type StockSnapshot = ReturnType<typeof makeStockSnapshot>;

export const makeStock = (snapshot?: Partial<StockSnapshot>) => {

  const s = observable({
    $: snapshot ?? makeStockSnapshot(),
    get id() {
      return s.$.id;
    },
  });
  return s;
}

export type Stock = ReturnType<typeof makeStock>;

