import { configure } from 'mobx';

/** enable MobX strict mode to prevent accidental non-action changes */
export const configureMobx = () => configure({
  enforceActions: 'observed',
  // computedRequiresReaction: true,
  // useProxies: 'ifavailable',
  // reactionRequiresObservable: true,
  // observableRequiresReaction: true,
  // disableErrorBoundaries: true
});
