import { action, observable, reaction, when } from "mobx";
import React, { SyntheticEvent } from 'react';
import { GenericEventHandler, Nullable } from "../../@types/base.types";
import { PointerType, SimpleOrientationType, UIControllerDeviceInfo } from "../../@types/ui.types";
import { DisplayMode } from "../../constants/DisplayMode.enum";
import { addToArrayIfNew, removeFromArray } from "../../utils/array.utils";
import { breakpoint } from "../../utils/breakpoints.utils";
import { detectBrowser } from "../../utils/browsers.utils";
import { setCSSCustomProperty } from "../../utils/css.utils";
import { debounce } from "../../utils/debounce.utils";
import { IS_PROD } from "../../utils/env.utils";
import addListener from "../../utils/eventListener.utils";
import { getScrollbarWidth } from "../../utils/ui.utils";
import { makeDialogController } from "../ui/dialog.controller";
import { makeNativeController } from "../ui/native.controller";
import PointerDetector from "../ui/pointerDetector";
import { makePortalController } from "../ui/portal.controller";
import { makeControllerBase, makeRootControllerChildInitFn } from "./root.controller";

/**
 * Manages common UI states, measurements, media queries, orientations,
 * event listeners, pointer detectors, dark UIs, and UI effects.
 * and prominent UI sub-controllers such as toasts, dialogs and overlays.
 *
 * Global UI related states and logic are hosted in this controller. It is also responsible for managing the dialogs, toasts (notification cards), and overlays.
 * Multiple Dialogs can be pushed to the controller, but only the latest one will be displayed. It will block the whole UI and user must choose an action before continuing.
 * Toasts are similar to dialogs, but it does not block the UI and user can choose to ignore them.
 * Overlays are similar in implementation but all overlays pushed to the stack will be rendered, with the most recent overlay on the top layer.
 *
 */
export const makeUIController = () => {

  const _private = observable({
    _appWidth: window.innerWidth,
    _appHeight: window.innerHeight,
  })

  const c = observable({

    ...makeControllerBase('UI'),

    preferDarkTheme: false,

    get appWidth(): number { return c.overrideAppWidth || _private._appWidth },
    get appHeight(): number { return c.overrideAppHeight || _private._appHeight },
    scrollBarWidth: 0,
    get displayMode(): DisplayMode {
      if (c.appWidth >= breakpoint('desktop')) return DisplayMode.desktop
      if (c.appWidth >= breakpoint('tablet')) return DisplayMode.tablet
      return DisplayMode.phone
    },
    get onlySmallPhones(): boolean { return c.appWidth <= 375 && c.appHeight < 575 },
    get onlyPhones(): boolean { return c.displayMode === 'phone' },
    get fromTablet(): boolean { return c.displayMode !== 'phone' },
    get fromTabletMd(): boolean { return c.appWidth >= breakpoint('tablet-md') },
    get fromTabletLg(): boolean { return c.appWidth >= breakpoint('tablet-lg') },
    get uptoTabletLg(): boolean { return c.appWidth < breakpoint('tablet-lg') },
    get onlyTablets(): boolean { return c.displayMode === 'tablet' },
    get uptoDesktop(): boolean { return c.appWidth < breakpoint('desktop') },
    get fromDesktop(): boolean { return c.displayMode === 'desktop' },
    get fromDesktopMd(): boolean { return c.appWidth >= breakpoint('desktop-md') },
    get fromDesktopLg(): boolean { return c.appWidth >= breakpoint('desktop-lg') },
    get fromTabletAndTall(): boolean { return c.fromTablet && c.appHeight >= 625 },

    get deviceInfo(): UIControllerDeviceInfo { return c.NATIVE.deviceInfo },

    deviceWidth: Math.max(window.screen.height, window.innerHeight),
    deviceHeight: Math.max(window.screen.width, window.innerWidth),
    get vMax(): number { return Math.max(c.appWidth, c.appHeight) },
    get vMin(): number { return Math.min(c.appWidth, c.appHeight) },
    get dMax(): number { return Math.max(c.deviceWidth, c.deviceHeight) },
    get dMin(): number { return Math.min(c.deviceWidth, c.deviceHeight) },
    get windowRatio(): number { return c.appWidth / c.appHeight },
    get deviceRatio(): number { return c.deviceWidth / c.deviceHeight },
    get orientation(): SimpleOrientationType {
      return c.windowRatio > 1 ? 'landscape' : 'portrait';
    },
    get deviceType() {
      if (c.deviceInfo.browser.includes('apple')) {
        if (c.deviceWidth <= 480 && c.deviceHeight <= 860) return 'phone';
        if (c.deviceWidth > 768 && c.deviceHeight > 1024) return 'desktop';
        return 'tablet';
      } else {
        if (c.dMin <= 480 && c.dMax <= 860) return 'phone';
        if (c.dMin > 768 && c.dMax > 1024) return 'desktop';
        return 'tablet';
      }
    },
    cssFeatures: {
      grid: window.CSS && window.CSS.supports('display', 'grid'),
      customProperties: window.CSS && window.CSS.supports('color', 'var(--c)')
    },

    appContainerRef: null as React.RefObject<HTMLDivElement> | null,
    registerAppContainerRef: action((ref: React.RefObject<HTMLDivElement>) => {
      c.appContainerRef = ref;
    }),
    overrideAppWidth: null as Nullable<number>,
    overrideAppHeight: null as Nullable<number>,
    hasInputFocus: false,
    updateCSSCustomProperties: () => {
      setCSSCustomProperty('--AppWidth', c.appWidth + 'px');
      setCSSCustomProperty('--AppHeight', c.appHeight + 'px');
    },
    $$debugOverrideAppWidthHeight: action((width?: number, height?: number) => {
      if (IS_PROD) return;
      if (width) _private._appWidth = width;
      if (height) _private._appHeight = height;
      c.updateCSSCustomProperties();
    }),
    measureWindowDimensions: action(() => {
      const appContainer = document.querySelector('.App');
      _private._appWidth = appContainer?.clientWidth ?? window.innerWidth;
      _private._appHeight = appContainer?.clientHeight ?? window.innerHeight;
      c.updateCSSCustomProperties();
    }),
    measureTemporaryWindowDimensionOverrides: action(() => {
      window.dispatchEvent(new Event('resize'));
      c.overrideAppWidth = window.innerWidth;
      c.overrideAppHeight = window.innerHeight;
      c.updateCSSCustomProperties();
    }),
    discardTemporaryWindowDimensionOverrides: action(() => {
      c.overrideAppWidth = null;
      c.overrideAppHeight = null;
      c.measureWindowDimensions();
    }),
    get deviceIsHighPerformance(): boolean {
      return c.NATIVE.assertedDevicePerformance === 'high';
    },
    get deviceIsLowPerformance(): boolean {
      return c.NATIVE.assertedDevicePerformance === 'low';
    },
    get enableBlurEffect(): boolean {
      if (!window.CSS || !window.CSS.supports) return false;
      const supportsBackdropFilter = CSS.supports('backdrop-filter', 'blur(1em)') || CSS.supports('-webkit-backdrop-filter', 'blur(1em)');
      return c.deviceIsHighPerformance && supportsBackdropFilter;
    },

    pointerTypes: [] as PointerType[],
    get canTouch(): boolean {
      return c.pointerTypes.includes('touch') || c.pointerTypes.includes('stylus');
    },
    get shouldUseGestures(): boolean {
      return c.pointerTypes.includes('touch') || c.uptoTabletLg;
    },

    windowEventCallbacks: {
      resize: [] as GenericEventHandler[],
      focus: [] as GenericEventHandler[],
      blur: [] as GenericEventHandler[],
      beforePrint: [] as GenericEventHandler[],
    },

    onResize: action((callback: GenericEventHandler) => {
      const { resize } = c.windowEventCallbacks;
      resize.push(callback);
      return () => {
        resize.splice(resize.indexOf(callback), 1);
      };
    }),
    onFocus: action((callback: GenericEventHandler) => {
      const { focus } = c.windowEventCallbacks;
      focus.push(callback);
      return () => {
        focus.splice(focus.indexOf(callback), 1);
      };
    }),
    onBlur: action((callback: GenericEventHandler) => {
      const { blur } = c.windowEventCallbacks;
      blur.push(callback);
      return () => {
        blur.splice(blur.indexOf(callback), 1);
      };
    }),
    onBeforePrint: action((callback: GenericEventHandler) => {
      const { beforePrint } = c.windowEventCallbacks;
      beforePrint.push(callback);
      return () => {
        beforePrint.splice(beforePrint.indexOf(callback), 1);
      };
    }),

    _handleWindowResize: debounce((e?: SyntheticEvent) => {
      c.measureWindowDimensions();
      c.windowEventCallbacks.resize.forEach(callback => callback instanceof Function && callback());
      document.documentElement.setAttribute('data-orientation', c.orientation);
    }, { timeout: 50, fireImmediately: true }),
    _handleWindowFocus: (e?: SyntheticEvent) => {
      c._handleWindowResize();
      c.windowEventCallbacks.focus.forEach(callback => callback instanceof Function && callback());
    },
    _handleWindowBlur: action((e?: SyntheticEvent) => {
      c._handleWindowResize();
      c.windowEventCallbacks.blur.forEach(callback => callback instanceof Function && callback());
    }),

    handleBeforePrint: (e?: SyntheticEvent) => {
      c.windowEventCallbacks.beforePrint.forEach(callback => callback instanceof Function && callback());
    },

    NATIVE: makeNativeController(),
    PORTAL: makePortalController(),
    DIALOG: makeDialogController(),

    openedUICardIds: new Set<string>(),
    registerOpenedUICard: action((id: string) => c.openedUICardIds.add(id)),
    deregisterOpenedUICard: action((id: string) => c.openedUICardIds.delete(id)),
    get hasOpenedUICards(): boolean {
      return c.openedUICardIds.size > 0;
    },

    portalFocusableElementStack: [] as (HTMLElement | SVGElement)[],
    focusOnElementInPortal: action((el: HTMLElement | SVGElement | null) => {
      if (!el) return;
      removeFromArray(c.portalFocusableElementStack, el);
      addToArrayIfNew(c.portalFocusableElementStack, el);
    }),

    get browserIsSeverelyOutdated(): boolean {
      return !c.cssFeatures.customProperties;
    },

    reset: () => {
      c.DIALOG.reset();
    },

  });

  const initGlobalListeners = async () => {
    await when(() => c.NATIVE.ready);
    addListener(window, 'resize', c._handleWindowResize);
    addListener(window, 'focus', c._handleWindowFocus);
    addListener(window, 'blur', c._handleWindowBlur);
    c._handleWindowResize();
    detectBrowser();
  }

  const initPointerDetector = () => {
    new PointerDetector({
      onDetectingMouse: action(() => c.pointerTypes.push('mouse')),
      onDetectingTouch: action(() => c.pointerTypes.push('touch')),
      onDetectingStylus: action(() => c.pointerTypes.push('stylus')),
    });
  }

  c.init = makeRootControllerChildInitFn(
    c,
    action(() => {
      const html = document.documentElement;
      initPointerDetector();
      initGlobalListeners();
      c.measureWindowDimensions();
      c.scrollBarWidth = getScrollbarWidth();

      reaction(
        () => c.displayMode,
        (current, prev) => {
          html.classList.remove(prev);
          html.classList.add(current);
          html.setAttribute('data-display-mode', current);
        },
        { fireImmediately: true }
      )
      reaction(
        () => c.fromTabletAndTall,
        () => html.setAttribute('data-from-tablet-and-tall', c.fromTabletAndTall ? 'true' : 'false'),
        { fireImmediately: true }
      )
      reaction(
        () => c.NATIVE.assertedDevicePerformance,
        current => html.setAttribute('data-perf', current),
        { fireImmediately: true }
      )
      reaction(
        () => c.enableBlurEffect,
        current => html.setAttribute('data-enable-blur-effect', current ? 'true' : 'false'),
        { fireImmediately: true }
      )
      if (!c.cssFeatures.grid) html.classList.add('nogrid');
      if (!c.cssFeatures.customProperties) {
        html.classList.add('novar');
      }
      c.ready = true;
    })
  )


  return c;

}

export type UIController = ReturnType<typeof makeUIController>;
