import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Link } from 'react-router-dom';
import BaseSpacer from '../BaseSpacer/BaseSpacer';
import LoginForm from '../LoginForm/LoginForm';
import './LoginPageContent.scss';

type LoginPageContentProps = {}

const LoginPageContent: React.FC<LoginPageContentProps> = props => {
  return <Observer children={() => (
    <div className="LoginPageContent">
        <LoginForm />
        <BaseSpacer size="ml" />
        <ul>
          <li><Link to="/forgot-password">→ Forgot your password?</Link></li>
          <li><a href="https://app.carraighill.com/contact" title="Carraighill Contact page">→ Contact Us</a></li>
          <li><a href="https://carraighill.com/" title="Carraighill Homepage">→ Go to Carraighill Homepage</a></li>
        </ul>
      { props.children }
    </div>
  )} />
}

export default LoginPageContent;