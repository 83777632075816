import { action } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React, { ReactNode } from 'react';
import { AnyObject, StringKeyOf } from '../../@types/base.types';
import joinClassName from '../../utils/className.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import { getRandomNumericString } from '../../utils/random.utils';
import './BaseCheckbox.scss';

export type BaseCheckboxProps<T extends AnyObject> = {
  className?: string,
  name?: string,
  Label?: ReactNode,
  form?: T,
  field?: StringKeyOf<T>,
  getter?: () => any,
  setter?: (v: any) => void,
}

const BaseCheckbox = <T extends AnyObject>(props: React.PropsWithChildren<BaseCheckboxProps<T>>) => {
  const p = useProps(props);
  const s = useStore(() => ({
    uniqueId: getRandomNumericString(),
    get id() {
      return `BaseCheckbox--${p.name || p.field || s.uniqueId}`
    },
    get value() {
      return p.getter ? p.getter() : (p.form && p.field) ? p.form[p.field] : false;
    },
    set value(v) {
      p.setter ? p.setter(v) : (p.form && p.field) ? p.form[p.field] = v : void null;
    },
    handleChange: action((e: React.FormEvent<HTMLInputElement>) => {
      s.value = (e.target as HTMLInputElement).checked as any;
    }),
    hasFocus: false,
    handleFocus: action(() => { s.hasFocus = true }),
    handleBlur: action(() => { s.hasFocus = false }),
  }));

  return <Observer children={() => (
    <div className={joinClassName('BaseCheckbox', p.className, s.value && 'isSelected', s.hasFocus && 'hasFocus')}data-value={s.value}>
      <input
        id={s.id}
        type="checkbox"
        checked={s.value} //This attribute isn't reflecting in HTML
        onChange={s.handleChange}
        onFocus={s.handleFocus}
        onBlur={s.handleBlur}
      />
      <label htmlFor={s.id} className="BaseCheckboxLabel">
        <span className="BaseCheckboxLabelMarker"></span>
        <span className="BaseCheckboxLabelContent">{p.Label}</span>
      </label>
    </div>
  )} />
}

export default BaseCheckbox;