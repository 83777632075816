import React from 'react';
import { ReportAccessibleIconType } from '../ReportDownloader/ReportDownloader';

type SearchDownloadIconProps = {
  type?: ReportAccessibleIconType,
}

const SearchDownloadIcon: React.FC<SearchDownloadIconProps> = props => {
  switch (props.type) {
    case ReportAccessibleIconType.disallow:
      // return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      // <line x1="20" y1="19.5" x2="4" y2="19.5" stroke="currentColor"/>
      // <path d="M12 3.99994L12 15.7705M12 15.7705L16.5 11.2705M12 15.7705L7.5 11.2705" stroke="currentColor"/>
      // <circle cx="12" cy="12" r="9" stroke="currentColor" stroke-width="2"/>
      // <line x1="4.62623" y1="17.9596" x2="17.9596" y2="4.62622" stroke="currentColor" stroke-width="2"/>
      // </svg>
      return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="20" y1="19.5" x2="4" y2="19.5" stroke="currentColor" />
        <path d="M12 3.99994L12 15.7705M12 15.7705L16.5 11.2705M12 15.7705L7.5 11.2705" stroke="currentColor" />
        <circle cx="12" cy="12" r="9.5" stroke="currentColor" />
        <line x1="4.97978" y1="18.3131" x2="18.3131" y2="4.97978" stroke="currentColor" />
      </svg>
    case ReportAccessibleIconType.allow:
    default:
      return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="20" y1="19.5" x2="4" y2="19.5" stroke="currentColor" />
        <path d="M12 3.99994L12 15.7705M12 15.7705L16.5 11.2705M12 15.7705L7.5 11.2705" stroke="currentColor" />
      </svg>
  }
}

export default SearchDownloadIcon;