import { flow } from "mobx";
import { Observer } from "mobx-react-lite";
import { useState } from "react";
import { useRootContext } from "../../controllers/root/root.controller";
import joinClassName from "../../utils/className.utils";
import { reportError } from "../../utils/errors.utils";
import { useStore } from "../../utils/mobx.utils";
import { makePasswordValidator } from "../../validators/password.validator";
import AppNavBar from "../AppNavBar/AppNavBar";
import BaseButton from "../BaseButton/BaseButton";
import BaseInput from "../BaseInput/BaseInput";
import BaseSpacer from "../BaseSpacer/BaseSpacer";
import Dialog from "../Dialog/DialogOld";
import PageContent from "../PageContent/PageContent";
import PageContentHeader from "../PageContentHeader/PageContentHeader";
import WindowTitle from "../WindowTitle/WindowTitle";
import './UpdatePassword.scss';

interface UpdatePasswordProps {}

const UpdatePassword: React.FC<UpdatePasswordProps> = props => {
  const { AUTH } = useRootContext();
  const [updatePasswordState, setUpdatePassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const s = useStore(() => ({
    form: {
      currentPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
    },
    passwordValidator: makePasswordValidator({ minLength: 8 }),
    get passwordValidatorResult() {
      return s.passwordValidator(s.form.newPassword);
    },
    get passwordIsValid() {
      return s.passwordValidatorResult === true;
    },
    get newPasswordMatches() {
      return s.form.newPassword === s.form.newPasswordConfirm;
    },
    get canSubmit() {
      return Boolean(s.form.currentPassword && s.form.newPassword && s.newPasswordMatches);
    },

    submit: async () => await flow(function* () {
      try {
        yield AUTH.updatePassword(s.form);
        setUpdatePassword('passwordUpdated');
      } catch (e: any) {
        setUpdatePassword('passwordUpdateFailed');
        reportError(e);
        if (e.response?.data?.errors ?? false) {
          const errorMessageKey = Object.keys(e.response.data.errors)[0];
          setErrorMessage(e.response.data.errors[errorMessageKey]);
        } else {
          setErrorMessage('Unexpected error occurred while resetting password!')
        }
      } finally {
      }
    })(),
    dismiss(){
      setUpdatePassword('');
    },
  }));

  return <Observer children={() => (
    <div className="UpdatePassword">
      <AppNavBar />
      <WindowTitle title="Reset Your Password" />
      <div className="PageAccountManagementContent">
        <PageContent>
          <div className="PageAccountManagementHeader">
            <PageContentHeader
              Title="Password &amp; Security"
              AfterTitle="Manage your Password."
            />
            <>
              <BaseButton disabled={!s.canSubmit} title="Confirm Change Password" onClick={s.submit}>Change Password</BaseButton>
              {updatePasswordState === 'passwordUpdateFailed' && <Dialog
                className={joinClassName('ErrorRenderer')}
                dialogHeading="Failed to Update Password"
                dialogMessage={errorMessage}
                onClickHandler={s.dismiss}
              />}
              {updatePasswordState === 'passwordUpdated' && <Dialog
                dialogHeading="Password Updated Successfully"
                onClickHandler={s.dismiss}
              />}
            </>
          </div>
          <BaseSpacer size="md"/>
          <p><strong>Reset Password</strong></p>
          <BaseSpacer size="md"/>
          <form className="PasswordForm AccountPersonalInfo">
            <BaseInput form={s.form} field="currentPassword" type="password" Label="Current Password" />
            <BaseSpacer />
            <BaseInput form={s.form} field="newPassword" type="password" Label="New Password" />
            <BaseSpacer />
            <BaseInput form={s.form} field="newPasswordConfirm" type="password" Label="Confirm New Password" />
          </form>
        </PageContent>
      </div>
      { props.children }
    </div>
  )} />
}

export default UpdatePassword;