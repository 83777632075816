import { action } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { useReportIndexContext } from '../../controllers/reportIndex.controller';
import { useRootContext } from '../../controllers/root/root.controller';
import { Category } from '../../models/makeCategory.model';
import { useProps, useStore } from '../../utils/mobx.utils';
import BaseCheckbox from '../BaseCheckbox/BaseCheckbox';
import './CategoryCheckboxList.scss';

interface CategoryCheckboxListProps {
  categories?: Category[],
  searchQuery?: string,
}

const CategoryCheckboxList = (props: React.PropsWithChildren<CategoryCheckboxListProps>) => {

  const { LOCALDB } = useRootContext();

  const p = useProps(props);

  const reportIndex = useReportIndexContext();

  const s = useStore(() => ({
    get categories() {
      return p.categories ?? LOCALDB.data.categories;
    },
    label: (category: Category) => {
      return `${category.$.name}`
    },
    matchesSearch: (category: Category) => {
      return s.label(category).toLowerCase().includes(p.searchQuery?.toLowerCase() ?? "") ?? false
    },
  }));

  return <Observer children={() => (
    <ul className="CheckboxList CategoryCheckboxList">
      {s.categories.map(category =>
        (!p.searchQuery || s.matchesSearch(category)) ?
          <li className="AnimateEntry" key={category.$.id}>
            <BaseCheckbox
              getter={() => reportIndex.categoryFilterIds.includes(category.$.id!)}
              setter={action(() => reportIndex.updateCategoryFilter(category))}
              Label={<>{s.label(category)}</>}
            />
          </li> :
          ""
      )}
    </ul>
  )} />
}

export default CategoryCheckboxList;