import { action, observable, runInAction, values } from "mobx";
import { AnyObject } from "../../@types/base.types";
import { Category, makeCategory } from "../../models/makeCategory.model";
import { Country, makeCountry } from "../../models/makeCountry.model";
import { makeProduct, Product } from "../../models/makeProduct.model";
import { makeReport, Report } from "../../models/makeReport.model";
import { makeStock, Stock } from "../../models/makeStock.model";
import { makeUser, User } from "../../models/makeUser.model";
import { clearArray } from "../../utils/array.utils";
import { RootController } from "./root.controller";

export type LocalDBController = ReturnType<typeof makeLocalDBController>;
export type ModelTypeName = keyof typeof modelConstructorMap;

const modelConstructorMap = {
  users: makeUser,
  reports: makeReport,
  categories: makeCategory,
  products: makeProduct,
  countries: makeCountry,
  banks: makeStock,
  // settings: {
  //   someFeature: true,
  // } ,
}

export const makeLocalDBController = () => {

  const s = observable({
    ROOT: null as RootController | null,
    data: {
      users: [] as User[],
      reports: [] as Report[],
      categories: [] as Category[],
      products: [] as Product[],
      countries: [] as Country[],
      banks: [] as Stock[],
    },
    addOne: <T>(type: ModelTypeName, snapshot: AnyObject) => {
      const model = modelConstructorMap[type](snapshot);
      runInAction(() => s.data[type].push(model as any));
      return model as unknown as T;
    },
    addMany: <T>(type: ModelTypeName, snapshots: AnyObject[]) => {
      return snapshots.map(snapshot => s.addOne<T>(type, snapshot));
    },
    reset: () => {
      values(s.data).forEach(array => clearArray(array as unknown[]));
    },
    init: action((root: RootController) => {
      s.ROOT = root;
    })
  });

  return s;

}
