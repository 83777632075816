import React from 'react';

type VideoCloseIconProps = {
}

const VideoCloseIcon: React.FC<VideoCloseIconProps> = props => {
  return <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.5 1L1 10.5M1 1L10.5 10.5" stroke="white" strokeWidth="2" />
  </svg>
}

export default VideoCloseIcon;