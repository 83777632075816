import { Observer } from "mobx-react-lite";
import React from "react";
import { useUIController } from "../../hooks/useRootController.hook";
import Dialog from "./Dialog";

export const DialogStack: React.FC = () => {
  const { DIALOG } = useUIController();
  return <Observer children={() => (
    DIALOG.lastDialog ? <Dialog dialog={DIALOG.lastDialog} key={DIALOG.lastDialog.id} /> : null
  )} />
}