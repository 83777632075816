import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { configureMobx } from './@configureMobx';
import App from './App';
import { RootContext, RootContextValue } from './controllers/root/root.controller';
import './index.scss';
import reportWebVitals from './reportWebVitals';

configureMobx();

ReactDOM.render(
  <React.StrictMode>
    <RootContext.Provider value={RootContextValue}>
      <Router>
        <App />
      </Router>
    </RootContext.Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
