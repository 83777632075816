import { action, observable } from "mobx";
import moment from "moment";
import React, { useContext } from "react";
import { Category } from "../models/makeCategory.model";
import { Country } from "../models/makeCountry.model";
import { Product } from "../models/makeProduct.model";
import { Report } from "../models/makeReport.model";
import { Stock } from "../models/makeStock.model";
import { clearArray, toggleOneInArray } from "../utils/array.utils";
import { IS_DEV } from "../utils/env.utils";
import { formatString } from "../utils/string.utils";
import { YYYYMMDD } from "../utils/time.utils";

export const makeReportIndexController = (
) => {

  const s = observable({
    _searchQuery: '',
    _showAccessibleOnly: false as boolean,
    _showFocusListOnly: false as boolean,
    reports: [] as Report[],
    timeframe: {
      start: moment().subtract(10, 'years').startOf('month').format(YYYYMMDD),
      end: moment().endOf('month').format(YYYYMMDD),
    },
    categoryFilterIds: [] as number[],
    productFilterIds: [] as number[],
    countryFilterIds: [] as number[],
    countryFilterNames: [] as string[],
    stockFilterIds: [] as number[],

    isLatestReports: false,
    showLatestReports: action(() => {
      var latestReports = [...s.reports]
      if (latestReports.length === 0) return;

      latestReports = latestReports.sort((a,b) => b.momentCreated.diff(a.momentCreated)).slice(0, Math.min(20, latestReports.length))

      s.timeframe.start = latestReports[latestReports.length - 1].momentCreated.format(YYYYMMDD)
      // s.timeframe.end = latestReports[0].momentCreated.format(YYYYMMDD)
      s.timeframe.end = moment().endOf('month').format(YYYYMMDD);
      s.isLatestReports = true
    }),

    updateTimeframeFilter: action((timeStart: string, timeEnd: string) => {
      s.timeframe.start = timeStart
      s.timeframe.end = timeEnd
      s.isLatestReports = false
    }),
    updateCategoryFilter: action((category: Category) => {
      toggleOneInArray(s.categoryFilterIds, category.$.id)
      s.isLatestReports = false
    }),
    updateProductFilter: action((product: Product) => {
      toggleOneInArray(s.productFilterIds, product.$.id)
      s.isLatestReports = false
    }),
    updateCountryFilter: action((country: Country) => {
      toggleOneInArray(s.countryFilterIds, country.$.id)
      toggleOneInArray(s.countryFilterNames, country.$.name)
      s.isLatestReports = false
    }),
    updateStockFilter: action((bank: Stock) => {
      toggleOneInArray(s.stockFilterIds, bank.$.id)
      s.isLatestReports = false
    }),
    clearCategoryFilter: action(() => {
      clearArray(s.categoryFilterIds)
    }),
    clearProductFilter: action(() => {
      clearArray(s.productFilterIds)
    }),
    clearCountryFilter: action(() => {
      clearArray(s.countryFilterIds)
      clearArray(s.countryFilterNames)
    }),
    clearStockFilter: action(() => {
      clearArray(s.stockFilterIds)
    }),

    get filteredReports() {
      const reportsSortedByLatestFirst = s.reports.filter(r => {
        const fallsWithinTimeframe = r.momentCreated.isSameOrAfter(s.timeframe.start, 'day') && r.momentCreated.isSameOrBefore(s.timeframe.end, 'day');
        const matchesAnyCategory =
          s.categoryFilterIds.length !== 0
            ? r.$.categories &&
              r.$.categories.some((category) =>
                s.categoryFilterIds.includes(category.id!)
              )
            : true;
        const matchesAnyProduct =
          s.productFilterIds.length !== 0
            ? r.$.products &&
              r.$.products.some((product) =>
                s.productFilterIds.includes(product.id!)
              )
            : true;
        const matchesAnyCountry =
          s.countryFilterIds.length !== 0
            ? (r.$.country && s.countryFilterNames.includes(r.$.country)) ||
              r.$.countries?.some((country) =>
                s.countryFilterIds.includes(country.id!)
              )
            : true;
        const matchesAnyStock = s.stockFilterIds.length ? (r.$.banks?.some(bank => s.stockFilterIds.includes(bank.id!))) : true;
        const show = !s.showAccessibleOnly || r.$.is_accessible
        const showFocusList = !s.showFocusListOnly || !!r.$.is_on_focus_list
        const isReportPublished = (r.$.status === 'published')

        return (
          show &&
          showFocusList &&
          fallsWithinTimeframe &&
          matchesAnyCategory &&
          matchesAnyProduct &&
          matchesAnyStock &&
          matchesAnyCountry &&
          isReportPublished
        );
      });
      reportsSortedByLatestFirst.sort((a,b) => b.momentCreated.diff(a.momentCreated));
      return reportsSortedByLatestFirst;
    },

    get reportsFilteredBySearch() {
      if (!s.searchQuery) return []
      const searchQuery = (s.searchQuery).replace(/[^a-zA-Z0-9]/g, '');
      const filteredReportsBySearch = s.reports.filter(r => (((r.formattedTitle).replace(/[^a-zA-Z0-9]/g, '')).includes(formatString(searchQuery))
        || (((r.formattedSubTitle).replace(/[^a-zA-Z0-9]/g, '')).includes(formatString(searchQuery)))));

      // sort by latest first.
      filteredReportsBySearch.sort((a,b) => b.momentCreated.diff(a.momentCreated));
      return filteredReportsBySearch;
    },
    get searchQuery() {
      return s._searchQuery
    },
    set searchQuery(v: string) {
      s._searchQuery = v
    },
    clearSearchQuery: action(() => {
      s.searchQuery = ""
    }),
    get showAccessibleOnly() {
      return s._showAccessibleOnly
    },
    set showAccessibleOnly(v: boolean) {
      s._showAccessibleOnly = v
    },
    toggleShowAccessible: action(() => {
      s.showAccessibleOnly = !s.showAccessibleOnly
    }),
    get showFocusListOnly() {
      return s._showFocusListOnly
    },
    set showFocusListOnly(v: boolean) {
      s._showFocusListOnly = v
    },
    toggleShowFocusListOnly: action(() => {
      s.showFocusListOnly = !s.showFocusListOnly
    }),
  })

  if (IS_DEV) {
    Reflect.set(window, 'reportIndex', s);
  }
  return s;
}

const FallbackReportIndexController = makeReportIndexController();
export const ReportIndexContext = React.createContext(FallbackReportIndexController);
export const useReportIndexContext = () => useContext(ReportIndexContext);
