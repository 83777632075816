import { action } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React, { ReactNode } from 'react';
import { AnyObject } from '../../@types/base.types';
import joinClassName from '../../utils/className.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import { getRandomNumericString } from '../../utils/random.utils';
import './BaseInput.scss';

export type TextInputEvent = React.FormEvent<HTMLInputElement | HTMLTextAreaElement | HTMLDivElement>;

export type BaseInputProps<T extends AnyObject> =  {
  type?: 'text' | 'number' | 'email' | 'password' | 'time' | 'search' | 'url' | 'tel'| 'date' | 'time' | 'search',
  Label?: ReactNode,
  step?: string | number,
  min?: string | number,
  max?: string | number,
  placeholder?: string,
  className?: string,
  autoComplete?: string;
  onClick?: (e: React.FormEvent) => unknown,
  autoFocus?: boolean;
  autoCapitalize?: string;
  autoCorrect?: string;
  field: keyof T,
  name?: string,
  form: T,
  getter?: () => any,
  setter?: (v: any) => void,
  required?: boolean,
  onEnter?: (v: string) => void,
  onChange?: (value?: any) => unknown,
  onFocus?: (e?: TextInputEvent) => unknown,
  onBlur?: (e?: TextInputEvent) => unknown,
}

const BaseInput = <T extends AnyObject>(props: React.PropsWithChildren<BaseInputProps<T>>) => {
  const p = useProps(props);
  const s = useStore(() => ({
    get name() {
      return p.name ?? p.field ?? getRandomNumericString();
    },
    get value() {
      return p.getter ? p.getter() : (p.form && p.field) ? p.form[p.field] : null;
    },
    set value(v) {
      p.setter ? p.setter(v) : (p.form && p.field) ? p.form[p.field] = v : void null;
    },
    handleChange: action((e: React.FormEvent<HTMLInputElement>) => {
      s.value = (e.target as HTMLInputElement).value;
      p.onChange?.(e)
    }),
    hasFocus: false,
    handleFocus: action((e: TextInputEvent) => {
      s.hasFocus = true
      p.onFocus?.(e)
    }),
    handleBlur: action((e: TextInputEvent) => {
      s.hasFocus = false
      p.onBlur?.(e)
    }),
    handleKeyUp: (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') p.onEnter?.(s.value);
    }
  }))
  return <Observer children={() => (
    <div className={joinClassName('BaseInput',s.hasFocus && 'hasFocus')}>
      {props.children}
      {p.Label && <label htmlFor={p.name}>
        {p.Label}
        {p.required && <em> * </em>}
      </label>}
      <input
        name={p.name}
        id={p.name}
        value={s.value}
        type={p.type}
        placeholder={p.placeholder}
        autoCapitalize={p.autoCapitalize}
        onFocus={s.handleFocus}
        onBlur={s.handleBlur}
        onChange={s.handleChange}
        onKeyUp={s.handleKeyUp}
      />
    </div>
  )} />
}

export default BaseInput;
