import { flow, observable } from "mobx";
import React, { useContext } from "react";
import { AnyObject } from "../../@types/base.types";
import { IS_DEV, SHOULD_LOG } from "../../utils/env.utils";
import { makeConstantPromise } from "../../utils/promises.utils";
import { CommonController, makeCommonController } from "../common.controller";
import { APIController, makeAPIController } from "./api.controller";
import { AuthController, makeAuthController } from "./auth.controller";
import { CategoriesController, makeCategoriesController } from "./categories.controller";
import { CountriesController, makeCountriesController } from "./countries.controller";
import { LocalDBController, makeLocalDBController } from "./localDB.controller";
import { makeNavigatorController, NavigatorController } from "./navigator.controller";
import { makeProductsController, ProductsController } from "./products.controller";
import { makeReportsController, ReportsController } from "./reports.controller";
import { makeSettingsController, SettingsController } from "./settings.controller";
import { makeStocksController, StocksController } from "./stocks.controller";
import { makeUIController, UIController } from "./ui.controller";

export const makeRootController = () => {

  const s: RootController = observable({
    LOCALDB: makeLocalDBController(),
    API: makeAPIController(),
    AUTH: makeAuthController(),
    REPORTS: makeReportsController(),
    NAVIGATOR: makeNavigatorController(),
    CATEGORIES: makeCategoriesController(),
    PRODUCTS: makeProductsController(),
    COUNTRIES: makeCountriesController(),
    STOCKS: makeStocksController(),
    SETTINGS: makeSettingsController(),
    UI: makeUIController(),
    COMMON: makeCommonController(),

    get ready() {
      return s.AUTH.ready;
    },
    init: async () => {

      s.LOCALDB.init(s);
      s.API.init(s);
      s.NAVIGATOR.init(s);
      await s.AUTH.init(s);

      s.REPORTS.init(s);
      s.CATEGORIES.init(s);
      s.PRODUCTS.init(s);
      s.COUNTRIES.init(s);
      s.STOCKS.init(s);
      s.SETTINGS.init(s);
      s.UI.init(s);
      s.COMMON.init(s);

      if (IS_DEV) {
        Reflect.set(window, 'LOCALDB', s.LOCALDB);
        Reflect.set(window, 'AUTH', s.AUTH);
        Reflect.set(window, 'REPORTS', s.REPORTS);
        Reflect.set(window, 'API', s.API);
        Reflect.set(window, 'NAVIGATOR', s.NAVIGATOR);
        Reflect.set(window, 'UI', s.UI);
        Reflect.set(window, 'CATEGORIES', s.CATEGORIES);
        Reflect.set(window, 'PRODUCTS', s.PRODUCTS);
        Reflect.set(window, 'COUNTRIES', s.COUNTRIES);
        Reflect.set(window, 'STOCKS', s.STOCKS);
        Reflect.set(window, 'SETTINGS', s.SETTINGS);
        Reflect.set(window, 'COMMON', s.COMMON);
      }
      return s;
    }
  });
  return s;
}
export const alwaysTrueInit = (name: string) => makeConstantPromise<true, RootController | undefined>(
  true,
  () => SHOULD_LOG() && console.log(`${name} init...`)
);
export const makeControllerBase = <T extends string = string>(name: T) => {
  const c = observable({
    ROOT: undefined as RootController | undefined,
    name,
    ready: false,
    init: alwaysTrueInit(name),
    children: {},
  })
  return c;
}
export const makeRootControllerChildInitFn = (
  controller: AnyObject,
  fn: Function,
  onError?: (e: Error | unknown) => void
) => {
  return (ROOT?: RootController) => new Promise<true>(
    flow(function * (resolve, reject) {
      try {
        controller.ROOT = ROOT;
        fn && (yield fn());
        controller.ready = true;
        resolve(true);
      } catch(e) {
        reject(e);
        onError && onError(e);
      }
    }
  ))
}

export type RootController = {
  LOCALDB: LocalDBController,
  AUTH: AuthController,
  API: APIController,
  REPORTS: ReportsController,
  NAVIGATOR: NavigatorController,
  CATEGORIES: CategoriesController,
  PRODUCTS: ProductsController,
  COUNTRIES: CountriesController,
  STOCKS: StocksController,
  SETTINGS: SettingsController,
  UI: UIController,
  COMMON: CommonController,

  ready: boolean,
  init: () => Promise<RootController>,
}

export const RootContextValue = makeRootController();
RootContextValue.init();
export const RootContext = React.createContext(RootContextValue);

export const useRootContext = () => useContext(RootContext);