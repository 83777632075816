import { Observer } from 'mobx-react-lite';
import React from 'react';
import { AnyObject } from '../../@types/base.types';
import joinClassName from '../../utils/className.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import { formatSearchString } from '../../utils/string.utils';
import BaseInput, { TextInputEvent } from '../BaseInput/BaseInput';
import IconSearch from '../IconSearch/IconSearch';
import './SearchBar.scss';

export interface SearchBarProps<T = AnyObject> {
  form?: T,
  field?: keyof T,
  className?: string,
  placeholder?: string,
  onChange?: (query: string) => unknown,
  onEnter?: (query: string) => unknown,
  onFocus?: (e?: TextInputEvent) => unknown,
  onBlur?: (e?: TextInputEvent) => unknown,
  autoComplete?: string,
  autoCapitalize?: string,
  autoCorrect?: string,
  autoFocus?: boolean,
}

const SearchBar: React.FC<SearchBarProps> = props => {
  const p = useProps(props);
  const s = useStore(() => ({
    _userInput: '',
    get userInput() {
      if (p.form && p.field) return p.form[p.field];
      return s._userInput;
    },
    set userInput(v: string) {
      if (p.form && p.field) p.form[p.field] = v;
      else s._userInput = v;
    },
    get formattedInput() {
      return formatSearchString(s.userInput);
    },
    handleChange() {
      p.onChange?.(s.formattedInput);
    },
    handleEnter() {
      p.onEnter?.(s.formattedInput);
    },
  }));

  return <Observer children={() => (
    <div className={joinClassName('SearchBar', p.className)}>
      <BaseInput
        form={s}
        field="userInput"
        type="search"
        onChange={s.handleChange}
        onEnter={s.handleEnter}
        onFocus={p.onFocus}
        onBlur={p.onBlur}
        placeholder={p.placeholder}
        autoComplete={p.autoComplete ?? 'off'}
        autoCorrect={p.autoCorrect ?? 'off'}
        autoCapitalize={p.autoCapitalize ?? 'off'}
        autoFocus={p.autoFocus}
      >
        {!s.userInput &&
          <div className="SearchBarIcon">
            <IconSearch />
          </div>
        }
      </BaseInput>

    </div>
  )} />
}

export default SearchBar;