
import { Device, DeviceInfo } from "@capacitor/device";
import { Haptics } from '@capacitor/haptics';
import { StatusBar, Style } from '@capacitor/status-bar';
import { flow, observable, reaction } from "mobx";
import { UIControllerDeviceInfo } from "../../@types/ui.types";
import { DisplayMode } from "../../constants/DisplayMode.enum";
import { detectBrowser } from "../../utils/browsers.utils";
import { versionAHigherThanB } from "../../utils/versions.utils";

export const makeNativeController = () => {

  const checkDeviceInfo = () => {
    return new Promise(flow(function * (resolve: (info: DeviceInfo) => unknown, reject: (e: Error) => unknown) {
      const info = yield Device.getInfo();
      c.deviceInfo.platform = info.platform;
      c.deviceInfo.osVersion = info.osVersion;
      c.deviceInfo.model = info.model;
      c.deviceInfo.manufacturer = info.manufacturer;
      c.ready = true;
      resolve(info);
    }))
  }

  const c = observable({

    ready: false,

    uiController: undefined as {
      displayMode: DisplayMode,
    } | undefined,

    deviceInfo: {
      platform: 'unknown',
      browser: detectBrowser(),
      osVersion: 'unknown',
      model: 'unknown',
      manufacturer: 'unknown',
    } as UIControllerDeviceInfo,

    get isApple(): boolean {
      return ['Macintosh', 'iPhone', 'iPad', 'iPod'].includes(c.deviceInfo.model);
    },
    get isSafari(): boolean {
      return c.deviceInfo.browser.includes('safari') || ['iPhone', 'iPad', 'iPod'].includes(c.deviceInfo.model);
    },
    get isAndroid(): boolean {
      return c.deviceInfo.browser.includes('android');
    },

    setStatusBarStyle(style: Style) {
      if (c.deviceInfo.platform === 'ios') {
        StatusBar.setStyle({ style });
      }
    },

    vibrate: () => {
      if (c.deviceInfo.platform === 'ios' || c.deviceInfo.platform === 'android') {
        Haptics.vibrate();
      }
    },

    get isIOS(): boolean {
      return Boolean(c.deviceInfo.model?.match(/^iP/));
    },

    // CONSIDER: make better
    get assertedDevicePerformance(): 'high' | 'low' {
      // if opened in a browser, deviceInfo.platform will always be web
      const { browser, osVersion, model } = c.deviceInfo;
      const isHigh = model === 'Macintosh' ||
        (model === 'iPhone' && versionAHigherThanB(osVersion, '14.0.0')) ||
        (model === 'iPad' && versionAHigherThanB(osVersion, '14.0.0')) ||
        (model === 'iPod' && versionAHigherThanB(osVersion, '14.0.0')) ||
        (browser.includes('chrome') && c.uiController?.displayMode === 'desktop');
      ;
      return isHigh ? 'high' : 'low';
    },

  })

  checkDeviceInfo();
  
  const html = document.documentElement;

  reaction(
    () => c.deviceInfo.platform,
    (current, prev) => {
      html.classList.remove(prev);
      html.classList.add(current);
      html.setAttribute('data-platform', current);
    },
  )
  reaction(
    () => c.deviceInfo.model,
    (current, prev) => {
      html.setAttribute('data-model', current);
      if (c.isApple) html.classList.add('apple');
    },
    { fireImmediately: true }
  )
  reaction(
    () => c.isIOS,
    (current) => {
      if (current) html.classList.add('iOS');
    },
    { fireImmediately: true }
  )
  reaction(
    () => c.deviceInfo.manufacturer,
    (current, prev) => {
      html.setAttribute('data-manufacturer', current);
    },
    { fireImmediately: true }
  )
  
  return c;

}

export type NativeController = ReturnType<typeof makeNativeController>;