import React from 'react';
import './ProfileIcon.scss';

type ProfileIconProps = {}

const ProfileIcon: React.FC<ProfileIconProps> = props => {
  return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M17.9057 16.2405C18.1042 16.5516 18.5587 16.5967 18.7567 16.2853C19.544 15.0465 20 13.5765 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 13.5765 4.45599 15.0464 5.24326 16.2852C5.44121 16.5966 5.89572 16.5516 6.0942 16.2404C7.33709 14.2921 9.51763 13 12 13C14.4823 13 16.6629 14.2921 17.9057 16.2405ZM14.6156 20.614C18.3106 19.4935 21 16.0609 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 14.3043 3.866 16.4064 5.29028 17.9984C5.29029 17.9984 5.29029 17.9985 5.29029 17.9985C5.29029 17.9985 5.29029 17.9985 5.2903 17.9985C6.36225 19.1967 7.75044 20.1061 9.32368 20.5954C10.1691 20.8583 11.068 21 12 21C12.9097 21 13.7879 20.865 14.6156 20.614ZM17.3989 17.9036C17.4997 17.8113 17.5308 17.6653 17.4747 17.5406C16.5353 15.4527 14.4362 14 12 14C9.60313 14 7.53266 15.406 6.57174 17.4398C6.48469 17.624 6.53123 17.8425 6.68343 17.9779C8.09676 19.2358 9.95914 20 12 20C12.1689 20 12.3367 19.9948 12.503 19.9844C14.3854 19.8677 16.0915 19.0999 17.3989 17.9036ZM12 11C13.1046 11 14 10.1046 14 9C14 7.89543 13.1046 7 12 7C10.8954 7 10 7.89543 10 9C10 10.1046 10.8954 11 12 11ZM12 12C13.6569 12 15 10.6569 15 9C15 7.34315 13.6569 6 12 6C10.3431 6 9 7.34315 9 9C9 10.6569 10.3431 12 12 12Z" fill="currentColor" />
    </svg>  
}

export default ProfileIcon;