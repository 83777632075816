import { observable } from "mobx";

export const makeProductSnapshot = () => ({
  id: null as null | number,
  name: '',
  description: '',
  type: '' ,
  slug: '',
  created_at: '' as string | null,
  updated_at: '' as string | null,
})

export type ProductSnapshot = ReturnType<typeof makeProductSnapshot>;

export const makeProduct = (snapshot?: Partial<ProductSnapshot>) => {

  const s = observable({
    $: snapshot ?? makeProductSnapshot(),
    get id() {
      return s.$.id;
    },
  });
  return s;
}

export type Product = ReturnType<typeof makeProduct>;

