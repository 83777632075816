import { action, observable, reaction } from "mobx";
import { RootController } from "./root.controller";

export const makeSettingsController = () => {
  const s = observable({
    ROOT: null as RootController | null,
    reset: () => {
    },
    getAllSettings: async () => {
      await s.ROOT?.API.getRaw('/api/settings');
    },
    init: action((root: RootController) => {
      s.ROOT = root;
      reaction(
        () => s.ROOT?.AUTH.isAuthenticated,
        isAuthenticated => isAuthenticated && s.getAllSettings(),
        { fireImmediately: true }
      )
    })
  })
  return s;
}

export type SettingsController = ReturnType<typeof makeSettingsController>;
