export const ResolutionMap = {
  nHD: [640, 360],
  SVGA: [800, 600],
  XGA: [1024, 768],
  WXGA: [1280, 720],
  HD: [1366, 768],
  WXGAPlus: [1440, 768],
  WSXGAPlus: [1680, 1050],
  FHD: [1920, 1080],
  QWXGA: [2048, 1152],
  QHD: [2560, 1440],
  WQHD: [3440, 1440],
  UHD4K: [3840, 2160],
  appleWatch38: [136, 170],
  appleWatch40: [162, 197],
  appleWatch42: [156, 195],
  appleWatch44: [184, 224],
  iPhoneSE: [320, 568],
  iPhone8: [375, 667],
  iPhone8Plus: [414, 736],
  iPhoneX: [375, 812],
  iPhone11: [414, 896],
  iPhone11Pro: [375, 812],
  iPhone11ProMax: [414, 896],
  GooglePixel2: [411, 731],
  GooglePixel2XL: [411, 823],
  GoogleNexus5X: [360, 640],
  iPad: [768, 1024],
  iPadPro11: [834, 1194],
  iPadPro12: [1024, 1366],
  MacBook13: [1280, 800],
  MacBook15: [1440, 900],
  MacBook16: [1792, 1120],
  iMac27: [2560, 1440],
}