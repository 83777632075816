import { action, observable, reaction } from "mobx";
import { equalByLowerCase } from "../../utils/string.utils";
import { RootController } from "./root.controller";

export const makeCountriesController = () => {
  const s = observable({
    ROOT: null as RootController | null,
    get countries() {
      return s.ROOT?.LOCALDB.data.countries;
    },
    reset: () => {
    },
    getAllCountries: async () => {
      await s.ROOT?.API.getMany('/api/countries', 'countries');
    },
    get countriesWithReports() {
      return (
        s.ROOT?.LOCALDB.data.countries.filter((country) =>
          s.ROOT?.LOCALDB.data.reports.find(
            (report) =>
              report.$.countries?.find((c) => equalByLowerCase(c.name, country.$.name)) ||
              equalByLowerCase(report.$.country, country.$.name)
          )
        ) ?? []
      );
    },
    getCountryIdByName: (name: string) => {
      return s.countriesWithReports.find((c) => c.$.name === name)?.$.id ?? null
    },
    init: action((root: RootController) => {
      s.ROOT = root;
      reaction(
        () => s.ROOT?.AUTH.isAuthenticated,
        isAuthenticated => isAuthenticated && s.getAllCountries(),
        { fireImmediately: true }
      )
    })
  })
  return s;

}

export type CountriesController = ReturnType<typeof makeCountriesController>;
