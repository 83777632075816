import React from 'react';

type IconCloseProps = {
}

const IconClose: React.FC<IconCloseProps> = props => {
  return <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_b)">
    <circle opacity="0.1" cx="18" cy="18" r="18" fill="currentColor"/>
    <path d="M25 11L11 25M11 11L25 25" stroke="currentColor"/>
    </g>
    <defs>
    <filter id="filter0_b" x="-20" y="-20" width="76" height="76" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
    <feGaussianBlur in="BackgroundImage" stdDeviation="10"/>
    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur" result="shape"/>
    </filter>
    </defs>
  </svg>
  
}

export default IconClose;