import { action, runInAction } from "mobx";

export const keepTruthy = <T>(arr: T[]): Exclude<T, false>[] => arr.filter(i => i) as Exclude<T, false>[];

export const clearArray = <T>(arr: T[]) => arr.splice(0, arr.length);
export const replaceContents = <T>(arr: T[], newContent: T[]) => arr.splice(0, arr.length, ...newContent);

export const addToArrayIfNew = <T>(
  arr?: T[],
  ...itemsToAdd: T[]
) => {
  if (!arr) return arr;
  for (let item of itemsToAdd) {
    if (arr.includes(item)) continue;
    runInAction(() => {
      arr.push(item);
    })
  }
  return arr;
}
export const addOneToArrayIfNew = <T>(
  arr?: T[],
  itemToAdd?: T
) => {
  if (!arr) return arr;
  if (arr.indexOf(itemToAdd as T) >= 0) return;
  arr.push(itemToAdd as T);
  return arr;
}

export const addManyToArrayIfNew = <T>(
  arr?: T[],
  ...itemsToAdd: T[]
) => {
  if (!arr) return arr;
  for (let item of itemsToAdd) {
    if (arr.includes(item)) continue;
    arr.push(item);
  }
  return arr;
}

export const moveToEndOfArray = <T>(arr: T[], a: T) => {
  removeFromArray(arr, a);
  arr.push(a);
  return arr;
}

/**
 * remove one item from array by exact match or reference
 */
export function removeOneFromArray<T>(
  arr: T[],
  itemToRemove: any) {
  const i = arr.indexOf(itemToRemove);
  if (i >= 0) arr.splice(i, 1);
  return arr;
}

/**
 * remove one or more item from array by exact match or references
 */
export const removeManyFromArray = <T>(
  arr?: T[],
  ...itemsToRemove: T[]
) => {
  if (!arr) return arr;
  for (let item of itemsToRemove) {
    const i = arr.indexOf(item);
    if (i >= 0) arr.splice(i, 1);
  }
  return arr;
}

export const toggleOneInArray = <T>(
  arr: T[],
  item: T,
) => {
  if (arr.includes(item)) removeOneFromArray(arr, item);
  else arr.push(item);
  return arr;
}

export const removeFromArray = action(<T>(arr?: T[], ...itemsToRemove: any) => {
  if (!arr) return arr;
  for (let item of itemsToRemove) {
    let i = arr.indexOf(item);
    while (i >= 0) {
      arr.splice(i, 1);
      i = arr.indexOf(item);
    }
  }
  return arr as T[];
})
