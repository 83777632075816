import { observable } from "mobx";

export const makeUserSnapshot = () => ({
  id: null as null | number | string,
  username: '',
  first_name: '',
  last_name: '',
  email: '',
  created_at: '' as string | null,
  phone: '',
  companyName : '',
  has_disabled_email_notifications: true as boolean | undefined, 
})

export type UserSnapshot = ReturnType<typeof makeUserSnapshot>;

export const makeUser = (snapshot?: Partial<UserSnapshot>) => {

  const s = observable({
    $: snapshot ?? makeUserSnapshot(),
    get id() {
      return s.$.id;
    },
    get fullName() {
      return [s.$.first_name, s.$.last_name].join(' ');
    },
  });

  return s;

}

export type User = ReturnType<typeof makeUser>;