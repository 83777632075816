import { CommonSize, lg, md, ml, Size, sm, xl } from "../constants/sizes.constants";
import { SHOULD_LOG } from "./env.utils";
import { ViewportSizeMap } from "./viewportSizes.utils";


export const BreakpointDefs = {
  'phone': [sm, sm],
  'phone-md': [sm, md],
  'phone-lg': [sm, lg],
  'phone-xl': [sm, xl],
  'tablet': [md, sm],
  'tablet-md': [md, md],
  'tablet-lg': [md, lg],
  'tablet-xl': [md, xl],
  'desktop': [ml, sm],
  'desktop-md': [ml, md],
  'desktop-ml': [ml, ml],
  'desktop-lg': [ml, lg],
  'desktop-xl': [ml, xl],
}

export const BreakpointNames = Object.keys(BreakpointDefs);

export type BreakpointName = keyof typeof BreakpointDefs;

export function isBreakpointName(string: any): string is BreakpointName {
  return BreakpointNames.includes(string);
}

export function breakpoint(a: CommonSize | Size.ml | BreakpointName, b?: CommonSize): number {
  let lv1: Size;
  let lv2: CommonSize;
  if (isBreakpointName(a)) {
    lv1 = BreakpointDefs[a][0] as CommonSize | Size.ml;
    lv2 = BreakpointDefs[a][1] as CommonSize;
  } else {
    lv1 = a;
    lv2 = b || sm;
  }
  if (!ViewportSizeMap[lv1][lv2]) {
    SHOULD_LOG() && console.error(`Warning: [${lv1}][${lv2}] is not defined in ViewportSizeMap. default value 1280 returned.`);
  }
  return ViewportSizeMap[lv1][lv2] ?? 1280;
}
