import React from 'react';
import joinClassName from '../../utils/className.utils';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import './LoadingBlocker.scss';

interface LoadingBlockerProps {
  className?: string,
  message?: React.ReactElement,
}

const LoadingBlocker: React.FC<LoadingBlockerProps> = props => {

  return <div className="LoadingBlocker">
    <div className="LoadingBlockerBackdrop" />
    <div className="LoadingBlockerInner">
      <LoadingIndicator />
      {
        props.message && (
          <div className={joinClassName("LoadingBlockerMessage", props.className)}>
            {props.message}
          </div>
        )
      }
    </div>
  </div>
}

export default LoadingBlocker;