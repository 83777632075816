import * as Sentry from '@sentry/react';
import { Nillable } from "../@types/base.types";
import { User } from "../models/makeUser.model";
import { SHOULD_LOG } from "./env.utils";

export const shouldStartSentry = process.env.NODE_ENV === 'production';

export function setErrorHandlerContext(user?: Nillable<User>) {
  if (!shouldStartSentry) return;
}

export function reportError(e: string | Error | unknown) {
  console.error(e);
  const error = typeof e === 'string' ? new Error(e) : e;
  if (shouldStartSentry) Sentry.captureException(error);
}

export function runAndIgnoreError(fn?: Function) {
  try {
    fn && fn()
  } catch(e) {
    SHOULD_LOG() && console.warn('An error was ignored:', e);
  }
} 