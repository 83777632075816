import { Observer } from 'mobx-react-lite';
import React from 'react';
import AppFooter from '../../components/AppFooter/AppFooter';
import LoginBackgroundImage from '../../components/LoginBackgroundImage/LoginBackgroundImage';
import ResetPassword from '../../components/ResetPassword/ResetPassword';
import WindowTitle from '../../components/WindowTitle/WindowTitle';
import '../PageForgotPassword/PageForgotPassword.scss';


type PageResetPasswordProps = {}

const PageResetPassword: React.FC<PageResetPasswordProps> = props => {
  return <Observer children={() => (
    <div className="AuthPage">
      <div className="AuthPageContainer">
        <WindowTitle title="Reset Password" />
        <span className="LoginBackgroundImage"><LoginBackgroundImage /></span>
        <main className="PageResetPassword">
          <div className="ForgotPasswordContent">
            <ResetPassword />
          </div>
        </main>
      </div>
      <footer>
        <AppFooter />
      </footer>
    </div>
  )} />
}

export default PageResetPassword;