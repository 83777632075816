import { action } from 'mobx';
import { Observer } from 'mobx-react-lite';
import moment from 'moment';
import React from 'react';
import { useReportIndexContext } from '../../controllers/reportIndex.controller';
import { useControllers } from '../../hooks/useRootController.hook';
import joinClassName from '../../utils/className.utils';
import { useStore } from '../../utils/mobx.utils';
import { YYYYMMDD } from '../../utils/time.utils';
import BaseButton from '../BaseButton/BaseButton';
import BaseCheckbox from '../BaseCheckbox/BaseCheckbox';
import BaseInput, { TextInputEvent } from '../BaseInput/BaseInput';
import BaseSpacer from '../BaseSpacer/BaseSpacer';
import CategoryCheckboxList from '../CategoryCheckboxList/CategoryCheckboxList';
import CountriesCheckboxList from '../CountriesCheckboxList/CountriesCheckboxList';
import IconToggle from '../IconToggle/IconToggle';
import SearchBar from '../SearchBar/SearchBar';
import SearchList from '../SearchList/SearchList';
import StocksCheckboxList from '../StocksCheckboxList/StocksCheckboxList';
import './ReportFilterForm.scss';

enum DateTimeFilter {
  "latest" = "latest",
  "by30Days" = "by30Days",
  "by12Months" = "by12Months",
  "all" = "all",
}

type ReportFilterFormProps = {
  autoOpen?: boolean,
}

const ReportFilterForm: React.FC<ReportFilterFormProps> = props => {

  const { LOCALDB, COUNTRIES } = useControllers();

  const reportIndex = useReportIndexContext();

  const s = useStore(() => ({
    _filterSearchQuery: '',
    get filterSearchQuery() {
      return s._filterSearchQuery
    },
    set filterSearchQuery(v: string) {
      s._filterSearchQuery = v
    },

    currentDateTimeFilter: DateTimeFilter.all as DateTimeFilter | "",
    categoryListOpen: props.autoOpen ?? true,
    productListOpen: false,
    countryListOpen: false,
    stockListOpen: false,

    toggleOpenState: action((listString: string) => {
      if (listString === 'categoryListOpen')
        s.categoryListOpen = !s.categoryListOpen;
      if (listString === 'productListOpen')
      s.productListOpen = !s.productListOpen;
      if (listString === 'countryListOpen')
        s.countryListOpen = !s.countryListOpen;
      if (listString === 'stockListOpen')
        s.stockListOpen = !s.stockListOpen;
    }),
    isDateTimeFilterActive: (datetimeFilter: DateTimeFilter) => {
      return s.currentDateTimeFilter === datetimeFilter;
    },
    filterReportsByDateTimeFilter: (datetimeFilter: DateTimeFilter) => action(() => {
      s.currentDateTimeFilter = datetimeFilter;
      switch (datetimeFilter) {
        case DateTimeFilter.by30Days:
          s.filterReportsBy30Days()
          return;
        case DateTimeFilter.by12Months:
          s.filterReportsBy12Months()
          return;
        case DateTimeFilter.all:
          s.filterReportsBy20Years()
          return;
        case DateTimeFilter.latest:
        default:
          s.filterReportsByLatest()
      }
    }),
    resetDateTimeFilter: action(() => {
      s.currentDateTimeFilter = ""
    }),
    filterReportsByLatest: action(() => {
      reportIndex.showLatestReports()
    }),
    filterReportsBy30Days: action(() => {
      reportIndex.updateTimeframeFilter(
        moment().subtract(30, 'days').format(YYYYMMDD),
        moment().format(YYYYMMDD)
      )
    }),
    filterReportsBy12Months: action(() => {
      reportIndex.updateTimeframeFilter(
        moment().subtract(12, 'months').format(YYYYMMDD),
        moment().format(YYYYMMDD)
      )
    }),
    filterReportsBy20Years: action(() => {
      reportIndex.updateTimeframeFilter(
        moment().subtract(20, 'years').startOf('month').format(YYYYMMDD),
        moment().endOf('month').format(YYYYMMDD)
      )
    }),
    selectAllCategories: action(() => {
      s.clearCategories()
      LOCALDB.data.categories.forEach((c) => reportIndex.updateCategoryFilter(c))
    }),
    selectAllProducts: action(() => {
      s.clearProducts()
      LOCALDB.data.products.forEach((c) => reportIndex.updateProductFilter(c))
    }),
    selectAllCountries: action(() => {
      s.clearCountries()
      COUNTRIES.countriesWithReports.forEach((c) => reportIndex.updateCountryFilter(c))
    }),
    selectAllStocks: action(() => {
      s.clearStocks()
      LOCALDB.data.banks.forEach((b) => reportIndex.updateStockFilter(b))
    }),
    clearCategories: action(() => {
      reportIndex.clearCategoryFilter();
    }),
    clearProducts: action(() => {
      reportIndex.clearProductFilter();
    }),
    clearCountries: action(() => {
      reportIndex.clearCountryFilter();
    }),
    clearStocks: action(() => {
      reportIndex.clearStockFilter();
    }),
    showSearchList: false,
    handleSearchBarFocus: action((e?: TextInputEvent) => {
      s.showSearchList = true
    }),
    handleSearchBarBlur: (e?: TextInputEvent) => {
    },
    handleSearchClear: action(() => {
      reportIndex.clearSearchQuery()
      s.showSearchList = false
    }),
  }))

  return <Observer children={() => (
    <div className="ReportFilterForm SubtleScrollbar">
      <form>
        <SearchBar className="ReportSearch" placeholder="Search report title..." form={reportIndex} field="searchQuery" onFocus={s.handleSearchBarFocus} onBlur={s.handleSearchBarBlur} />
        <div className={joinClassName("SearchReportResults", !s.showSearchList && "hide")}>
          <SearchList searchedReports={reportIndex.reportsFilteredBySearch} />
          <div className="SearchReportClearButtonContainer">
            <BaseButton className="SearchReportClearButton" onClick={s.handleSearchClear}>Clear Search</BaseButton>
          </div>
        </div>
        <div className={joinClassName("ReportFilterFormContent", s.showSearchList && "hide")}>
          <h4>Publication Period</h4>
          <BaseSpacer />
          <div className="ReportFilterDate">
            <BaseInput type="date" form={reportIndex.timeframe} field="start" onChange={s.resetDateTimeFilter} />
            <p>to</p>
            <BaseInput type="date" form={reportIndex.timeframe} field="end" onChange={s.resetDateTimeFilter} />
          </div>
          <BaseSpacer size=".95em" />
          <span className="ReportFilterByMonthsHeader">Quick Select</span>
          <BaseSpacer size=".7em" />
          <div className="ReportFilterByMonths">
            <BaseButton className={joinClassName(s.isDateTimeFilterActive(DateTimeFilter.latest) ? 'disabled' : "")} onClick={s.filterReportsByDateTimeFilter(DateTimeFilter.latest)}>Latest</BaseButton>
            <BaseButton className={joinClassName(s.isDateTimeFilterActive(DateTimeFilter.by30Days) ? 'disabled' : "")} onClick={s.filterReportsByDateTimeFilter(DateTimeFilter.by30Days)}>Last 30 Days</BaseButton>
            <BaseButton className={joinClassName(s.isDateTimeFilterActive(DateTimeFilter.by12Months) ? 'disabled' : "")} onClick={s.filterReportsByDateTimeFilter(DateTimeFilter.by12Months)}>Last 12 Months</BaseButton>
            <BaseButton className={joinClassName(s.isDateTimeFilterActive(DateTimeFilter.all) ? 'disabled' : "")} onClick={s.filterReportsByDateTimeFilter(DateTimeFilter.all)}>All</BaseButton>
          </div>
          <BaseSpacer size="1em" />
          <BaseCheckbox
            getter={() => reportIndex.showFocusListOnly}
            setter={action(() => reportIndex.toggleShowFocusListOnly())}
            Label={<>HFL</>}
            className="AffectFocusList"
          />
          <BaseSpacer size=".2em" />
          <h4>Filters</h4>
          <BaseSpacer size=".7em" />
          <SearchBar className="FilterSearch" placeholder="Find a filter..." form={s} field="filterSearchQuery" />
          {/* <BaseCheckbox
            getter={() => reportIndex.showAccessibleOnly}
            setter={action(() => reportIndex.toggleShowAccessible())}
            Label={<>Show only accessible</>}
          /> */}
          
          
          <div className="ReportByCategories">
            <div className="ReportByCategoriesHeader">
              <h4 className={joinClassName(s.categoryListOpen ? 'toggleOpen' : '')} onClick={() => s.toggleOpenState('categoryListOpen')}>{s.categoryListOpen ? <IconToggle className="" /> : <IconToggle className="ToggleUp" />}Categories</h4>
              {/* {IS_DEV && <BaseButton onClick={s.selectAllCategories}>Select All</BaseButton>} */}
              <BaseButton onClick={s.clearCategories}>Clear</BaseButton>
            </div>
            <BaseSpacer size="sm" />
            {s.categoryListOpen && <CategoryCheckboxList searchQuery={s.filterSearchQuery} />}
          </div>
          <div className="ReportByCountries">
            <div className="ReportByCountriesHeader">
              <h4 className={joinClassName(s.countryListOpen ? 'toggleOpen' : '')} onClick={() => s.toggleOpenState('countryListOpen')}>{s.countryListOpen ? <IconToggle className="" /> : <IconToggle className="ToggleUp" />}Countries</h4>
              {/* {IS_DEV && <BaseButton onClick={s.selectAllCountries}>Select All</BaseButton>} */}
              <BaseButton onClick={s.clearCountries}>Clear</BaseButton>
            </div>
            <BaseSpacer size="sm" />
            {s.countryListOpen && <CountriesCheckboxList searchQuery={s.filterSearchQuery} />}
          </div>
          <div className="ReportByStocks">
            <div className="ReportByStocksHeader">
              <h4 className={joinClassName(s.stockListOpen ? 'toggleOpen' : '')} onClick={() => s.toggleOpenState('stockListOpen')}>{s.stockListOpen ? <IconToggle className="" /> : <IconToggle className="ToggleUp" />}Stocks</h4>
              {/* {IS_DEV && <BaseButton onClick={s.selectAllStocks}>Select All</BaseButton>} */}
              <BaseButton onClick={s.clearStocks}>Clear</BaseButton>
            </div>
            <BaseSpacer size="sm" />
            {s.stockListOpen && <StocksCheckboxList searchQuery={s.filterSearchQuery} />}
          </div>
          {/* <div className="ReportByProducts">
            <div className="ReportByProductsHeader">
              <h4 onClick={() => s.toggleOpenState('productListOpen')}>{s.productListOpen ? <IconToggle className="" /> : <IconToggle className="ToggleUp" />} Products</h4>
              <BaseButton onClick={s.clearProducts}>Clear</BaseButton>
            </div>
            <BaseSpacer size="sm" />
            {s.productListOpen && <ProductCheckboxList searchQuery={s.filterSearchQuery} />}
          </div> */}
        </div>
      </form>
    </div>
  )} />
}

export default ReportFilterForm;