import { Observer } from 'mobx-react-lite';
import React from 'react';
import { isBetaSite, isLocalhost, isOfficialSite, isStagingSite } from '../../env';
import { useOnMount } from '../../hooks/lifecycle.hooks';
import { useAppVersion } from '../../hooks/useAppDisplayName.hook';
import { capitalizeFirstLetter } from '../../utils/string.utils';
import './InternalSiteMarker.scss';

interface InternalSiteMarkerProps {}

const InternalSiteMarker: React.FC<InternalSiteMarkerProps> = props => {
  
  const { hostname } = window.location;
  const version = useAppVersion();

  useOnMount(() => {
    // if (!isOfficialSite) {
      const titleTag = document.getElementsByTagName('title');
      if (!titleTag[0]) return;
      let hostnameDisplay = hostname;
      switch (hostname) {
        case 'www.beta.carraighill.com': hostnameDisplay = 'Beta Preview'; break;
        case 'www.carraighill.axon.host': hostnameDisplay = 'Staging Preview'; break;
        default: hostnameDisplay = capitalizeFirstLetter(hostname); break;
      }
      titleTag[0].innerText = `${hostnameDisplay} • ` + titleTag[0].innerText + ` [${isLocalhost ? 'DEV:' : ''}${version}]`;
    // }
  });
  return <Observer children={() => (
    <div className="InternalSiteMarker" data-hostname={hostname}>
      {
        (isLocalhost || !isOfficialSite || isBetaSite || isStagingSite) && (
          <div className="InternalSiteMarkerInner">
            {isLocalhost && <span className="InternalSiteMarkerEnv">Dev</span>}
            {isBetaSite && <span className="InternalSiteMarkerEnv">Beta</span>}
            {isStagingSite && <span className="InternalSiteMarkerEnv">Staging</span>}
            <span className="InternalSiteMarkerVersion">{version}</span>
          </div>
        )
      }
    </div>
  )} />
}

export default InternalSiteMarker;