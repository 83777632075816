import { Observer } from 'mobx-react-lite';
import React from 'react';
import joinClassName from '../../utils/className.utils';
import { useProps } from '../../utils/mobx.utils';
import BaseButton from '../BaseButton/BaseButton';
import './DialogOld.scss';

type DialogOldProps = {
  dialogHeading?: string,
  dialogMessage?: string,
  onClickHandler?: any,
  dialogButtonOption?: string,
  className?: string,
}

const DialogOld: React.FC<DialogOldProps> = props => {
  const p = useProps(props);

  return <Observer children={() => (
    <div className={joinClassName('DialogOld', p.className)}>
      <span className="DialogOldBackdrop"></span>
        <div className="DialogOldLayer">
          <div className="DialogOldHeader">
            <h1 className="DialogOldHeading">{props.dialogHeading}</h1>
          </div>
          <div className="DialogOldBody">
            <p>{props.dialogMessage}</p>
          </div>
          <div className="DialogOldFooter">
            {/* <BaseButton className="negative" title="Cancel" Label="Cancel" onClick={props.onClickHandler}/> */}
            <BaseButton title="OK" Label="OK" onClick={props.onClickHandler}/>
          </div>
        </div>
    </div>
  )} />
}

export default DialogOld;