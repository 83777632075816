import packageJson from '../../../package.json';

export const AppInfo = {
  name: packageJson.name,
  displayName: packageJson['display-name'],
  version: packageJson.version,
};
export type AppInfoSet = typeof AppInfo;

console.log(`%cWelcome to ${AppInfo.displayName}! [ Version ${AppInfo.version} ] ✨`, 'color: #21a129')
console.log(`%cApp Designed & Developed by Viralbamboo Ltd. [https://viralbamboo.com]`, 'color: #21a129')
