import { action } from 'mobx';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { Link } from 'react-router-dom';
import sanitizeHtml from 'sanitize-html';
import { useControllers } from '../../hooks/useRootController.hook';
import { Report } from '../../models/makeReport.model';
import joinClassName from '../../utils/className.utils';
import { IS_DEV } from '../../utils/env.utils';
import { useProps, useStore } from '../../utils/mobx.utils';
import { getDateFromUTC } from '../../utils/time.utils';
import BaseSpacer from '../BaseSpacer/BaseSpacer';
import IconUp from '../IconUp/IconUp';
import PlayVideoIcon from '../PlayVideoIcon/PlayVideoIcon';
import ReportDownloader, { DownloaderViewType, ReportAccessibleIconType } from '../ReportDownloader/ReportDownloader';
import ReportImage from '../ReportImage/ReportImage';
import SanitizedHTMLContainer from '../SanitizedHTMLContainer/SanitizedHTMLContainer';
import VideoWrapper from '../VideoWrapper/VideoWrapper';
import './ReportEntry.scss';


type ReportEntryProps = {
  report: Report,
  autoOpen?: boolean,
  onChange?: (v: number) => void,
  currentOpenedReport?: number
}

const ReportEntry: React.FC<ReportEntryProps> = props => {

  const { UI, NAVIGATOR } = useControllers();

  const p = useProps(props);

  const s = useStore(() => ({
    get open(){
      let currentReportId = +p.report.id!;
      return p.currentOpenedReport === currentReportId;
    },
    toggleOpenState: action(() => {
      p.onChange?.(+p.report.id!);
      if(p.currentOpenedReport === +p.report.id!){
        p.onChange?.(0);
      }
      IS_DEV && console.log(!!p.report.$.is_on_focus_list + '' + p.report.$.id);
    }),
    showVideo: false,
    toggleVideo: action((e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (!s.canAccess) {
        NAVIGATOR.redirectTo(`/reports/${p.report.$.id}`);
        UI.DIALOG.alert({
          heading: "Limited access with current plan",
          body: "Your current plan does not allow you to access the report's video. Please contact admin@carraighill.com for more information.",
        })
        return;
      }
      s.showVideo = !s.showVideo
    }),
    get videoLink() {
      return p.report.$.video_path ?? "";
    },
    get canAccess() {
      return p.report.$.is_accessible;
    },
    get reportAccessibleIconType() {
      return s.canAccess ? ReportAccessibleIconType.allow : ReportAccessibleIconType.disallow
    },
    get sanitizedContent(){
      return sanitizeHtml(p.report.$.description!);
    }
  }));
  return <Observer children={() => (
    <div className={joinClassName("ReportEntry")}>
      <header className="ReportEntryHeader" onClick={s.toggleOpenState}>
      {/* <header className="ReportEntryHeader" onClick={p.onChange?.(p.report.$.id!)}> */}
        <div className="ReportEntryHeaderIndex">
          <p className="ReportEntryPublishedDate">{getDateFromUTC(props.report.$.created_at!)}</p>
          {(s.open && UI.onlyPhones) && <ReportImage report={p.report} />}
          <h3 className="ReportEntryTitle">{p.report.$.title} {p.report.$.subtitle! && UI.fromTablet && ': '}
          {p.report.$.subtitle! && <p>{p.report.$.subtitle}</p>}</h3>
        </div>
        <div className="ReportEntryHeaderControls">
          {s.open && !!s.videoLink &&
            <div onClick={s.toggleVideo}>
              <div className={joinClassName("PlayVideoIconWrapper", s.canAccess ? "" : "disallow")}>
                <PlayVideoIcon type={s.reportAccessibleIconType} />
              </div>
              {s.showVideo &&
                <VideoWrapper id="ReportVideo" src={s.videoLink} autoPlay loop controls fullscreenOnly hideVideo={s.toggleVideo} />
              }
            </div>
          }
          <ReportDownloader report={p.report} downloaderViewType={DownloaderViewType.REPORT_ENTRY} />
          {s.open ? <IconUp className="IconUp" /> : <IconUp className="" />}
        </div>
      </header>
      {s.open &&
        <div className="ReportEntryHeaderOpen AnimateEntry">
          <div className="ReportContentContainer">
            {/* <span className="ReportEntryHeaderSubtitle">{p.report.$.subtitle}</span> */}
            {/* {p.report.$.subtitle! && <BaseSpacer />} */}
            <div className="ReportContent">
              {props.report.$.intro_description! && <SanitizedHTMLContainer source={props.report.$.intro_description} /> }
              {!props.report.$.intro_description! && <SanitizedHTMLContainer source={props.report.$.description} /> }
            </div>
            <BaseSpacer />
            <Link to={`/reports/${p.report.$.id}`}>Read More</Link>
          </div>
          {UI.fromTablet && <ReportImage report={p.report} />}
        </div>
      }
    </div>
  )} />
}

export default ReportEntry;