import { Validator } from "../@types/validators.types";
import { autoPluralize } from "../utils/string.utils";


export function makePasswordValidator(options?: {
  minLength: number,
}) {
  const { minLength = 6 } = options || {};
  const validator: Validator = (
    value?: string
  ) => {
    let message = 'Please provide a valid password.';
    if (!value) return {
      isValid: false,
      message,
    } 
    const passwordHasMinLength = value && value.length >= minLength;
    const isValid = !!(passwordHasMinLength);
    if (isValid) return true;
    if (!passwordHasMinLength) message = `Your password must be at least ${autoPluralize(minLength, 'character')} long.`;
    return { isValid, message };
  }
  return validator;
}

export const defaultPasswordValidator = makePasswordValidator({ minLength: 6 });